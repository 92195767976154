<template>
  <section class="testimonail-list sy" >
    <div class="container">
      <div class="sy">
        <swiper class="swiper" 
        :options="testimonailSlide">
          <swiper-slide
            v-for="(testimonail, index) in testimonial.testimonial"
            :key="'testimonail' + index"
          >
            <div class="testimonail">
              <div class="icon"><b-icon-chat-quote></b-icon-chat-quote></div>
              <div class="message">
                <p>{{ testimonail.message }}</p>
              </div>
              <div class="info">
                <div class="image">
                  <img :src=" assetUrl + testimonail.profile" :alt="testimonail.name" class="img-fluid" />
                </div>
                <div class="center content">
                  <div class="content-center">
                    <div class="box-content">
                      <div class="title">
                        <h5>{{ testimonail.name }}</h5>
                      </div>
                      <div class="des two-line">
                        {{ testimonail.position }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <swiper class="swiper client-list" 
      :options="clientSlide"
      >
        <swiper-slide
          v-for="(client, index) in testimonial.client"
          :key="'client' + index"
        >
          <div class="client my-3">
            <b-link :href="client.link" target="_blank">
              <div class="image">
                <img :src=" assetUrl + client.image" :alt="client.title" class="img-fluid" />
              </div>
            </b-link>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
  import { Swiper, SwiperSlide,Grid} from 'vue-awesome-swiper';
  import { mapActions, mapGetters } from 'vuex';
  export default {
    name: 'Testimonail',
    props: ['Testimonails', 'Clients'],
    components: {
      Swiper,
      SwiperSlide,
      Grid
    },
    
    data() {
      return {
        assetUrl: process.env.VUE_APP_ASSET_URL + '/',
        serviceBackground: require('@/assets/images/cta_bg1.png'),
        visible: false,
        clientSlide: {
          slidesPerView: 3,
          spaceBetween: 10,
          slidesPerGroup: 1,
          speed:1000,

          slidesPerColumn: 3,
          slidesPerColumnFill: 'row',
          autoplay: {
            delay: 9000,
            disableOnInteraction: false,
          },
          breakpoints: {
            576:{
              spaceBetween: 15,
            },
            768:{
              slidesPerView: 4,
              spaceBetween: 20,

            },
            992:{
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }
        },
        testimonailSlide: {
          loop: true,
          slidesPerView: 2,
          spaceBetween: 10,
          slidesPerGroup: 2,
          autoplay: {
            delay: 1000000,
            disableOnInteraction: false,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          breakpoints: {
            576:{
              spaceBetween: 30,
            }
          }
        },
      };
      
    },
    methods: {
      ...mapActions(['fetchTestimonial']),
    
    },
    computed: {
      ...mapGetters(['testimonial']),
  
    },
    mounted() {
      this.fetchTestimonial({
        filter: {
          locale: this.$router.currentRoute.params.locale
            ? this.$router.currentRoute.params.locale
            : 'en',
        },
      });
    },
    watch: {
      $route(to, from) {
        if (from.params.locale && to.params.locale !== from.params.locale) {
          this.fetchTestimonial({
            filter: {
              locale: this.$router.currentRoute.params.locale
                  ? this.$router.currentRoute.params.locale
                  : "en",
            },
          });
        }
      },
    },
  };
</script>

<style lang="scss">
  @import '@assets/scss/components/testimonail.scss';
  @import '@core/assets/scss/swiper';
</style>
