<template>
  <div class="team-detail">
    <breadcrumb
        v-bind:title="teamDetail && teamDetail.breadcrumb && teamDetail.breadcrumb.title ? teamDetail && teamDetail.breadcrumb && teamDetail.breadcrumb.title : bg_title "
        v-bind:backgroundImg="teamDetail && teamDetail.breadcrumb && assetUrl+teamDetail.breadcrumb.image ? teamDetail && teamDetail.breadcrumb && assetUrl+teamDetail.breadcrumb.image : bg_img"
    >{{teamDetail && teamDetail.breadcrumb}}
    </breadcrumb>
    <div class="content-detail">
      <b-container>
        <b-row class="row-detail">
          <b-col md="4">
              <div class="team-thumnbnail-wrap">
                <b-img :src="assetUrl+teamDetail && teamDetail.teamDetail && assetUrl + teamDetail.teamDetail.profile" class="img-fluid" alt=""></b-img>
              </div>
          </b-col >
          <b-col md="8 d-flex align-items-center">
            <div class="team-content">
              <h1 class="member-name">{{teamDetail && teamDetail.teamDetail && teamDetail.teamDetail.name}}</h1>
              <span class="member-position">{{teamDetail && teamDetail.teamDetail && teamDetail.teamDetail.position}}</span>
            <div >
              <p v-html="teamDetail && teamDetail.teamDetail && teamDetail.teamDetail.description"></p>
            </div>
            <!-- <span class="social">
              <b-link target="_blank" :href="teamDetail && teamDetail.teamDetail && teamDetail.teamDetail.facebook"><b-icon icon="facebook"></b-icon></b-link>
              <b-link target="_blank" :href="teamDetail && teamDetail.teamDetail && teamDetail.teamDetail.twitter"><b-icon icon="twitter"></b-icon></b-link>
              <b-link target="_blank" :href="teamDetail && teamDetail.teamDetail && teamDetail.teamDetail.linkedin"><b-icon icon="linkedin"></b-icon></b-link>
              <b-link target="_blank" :href="teamDetail && teamDetail.teamDetail && teamDetail.teamDetail.instagram"><b-icon icon="instagram"></b-icon></b-link>
            </span> -->
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import breadcrumb from "@core/components/breadcrumb/Breadcrumb";
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'TeamDetail',

  components:{
    breadcrumb
  },

  data () {
    return {
      assetUrl: process.env.VUE_APP_ASSET_URL + '/',
      bg_img:require('@assets/images/blog-details/blog_detail_03.jpg'),
      bg_title:'Team Detail',
      items: [
        {
          text: 'Home',
          href: '#'
        },
        {
          text: 'Team Member',
          href: '#',
          active: true
        },
      ],
      member_info:[
        {
          id:1,
          src:require('@assets/images/team-member/member-1.jpg'),
          name:'Wallace S. Camacho',
          position:'Product Manager',
          alt:'img-1'
        },
      ]
    }
  },

  methods: {
  ...mapActions(['fetchTeamDetail']),
  
  },

  computed: {
  ...mapGetters(['teamDetail']),

  },

  mounted() {
      this.fetchTeamDetail({
          filter: {
          locale: this.$router.currentRoute.params.locale
              ? this.$router.currentRoute.params.locale
              : 'en',
          },
          uuid: this.$router.currentRoute.query._post ? this.$router.currentRoute.query._post: null,
      });
  },

  watch: {
      $route(to, from) {
          if (from.params.locale && to.params.locale !== from.params.locale) {
          this.fetchTeamDetail({
              filter: {
                  locale: this.$router.currentRoute.params.locale
                  ? this.$router.currentRoute.params.locale
                  : "en",
              },
              uuid: this.$router.currentRoute.query
              ? this.$router.currentRoute.query._post
              : null,
          });
      }
      },
  },

  // seo
  metaInfo () {
    let locale = localStorage.getItem('_locale')
    locale = locale ? JSON.parse(locale) : null
    return {
      title: (this.teamDetail && this.teamDetail.breadcrumb && this.teamDetail.breadcrumb.title
      ? this.teamDetail.breadcrumb.title
      : 'Team detail'),
      titleTemplate:
              '%s | ' +
              (this.$root.general.settings.app_name
              ? this.$root.general.settings.app_name
              : 'Aditi Wesbite'),
      htmlAttrs: {
      lang: locale && locale.title ? locale.title : 'en'
      },
      meta: [
      {
          vmid: 'description',
          name: 'description',
          content: (this.teamDetail && this.teamDetail.breadcrumb && this.teamDetail.breadcrumb.description)
          ? this.teamDetail.breadcrumb.description
          : (this.$root.general.settings.description
              ? this.$root.general.settings.description
              : '')
      },
      {
          vmid: 'keyword',
          name: 'keyword',
          content: (this.teamDetail && this.teamDetail.breadcrumb && this.teamDetail.breadcrumb.keyword)
          ? this.teamDetail.breadcrumb.keyword
          : ''
      }
      ]
    }
  },

}
</script>

<style lang="scss">
@import "@assets/scss/pages/team-detail/team-detail.scss";
</style>