var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row card-team"},_vm._l((_vm.team_infos),function(member,id){return _c('div',{key:id,staticClass:"col-lg-3 col-md-4 col-6 responsive-col"},[_c('div',{staticClass:"single-team"},[_c('div',{staticClass:"team"},[_c('router-link',{staticClass:"team-image",attrs:{"to":{ 
            name:'team-detail',
            query: {
              _post: member.uuid,
              text: member.slug,
            },
          }}},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.assetUrl + member.profile,"alt":member.name}})])],1),_c('div',{staticClass:"team-content-botton"},[_c('div',{staticClass:"member-identity"},[_c('h6',{staticClass:"card-text name pt-2"},[_c('b-link',{attrs:{"href":"#"}},[_vm._v(_vm._s(member.name))])],1),_c('span',{staticClass:"card-text position"},[_c('b-link',{attrs:{"href":"#"}},[_vm._v(_vm._s(member.position))])],1)])])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }