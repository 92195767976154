<template>
  <div class="privacy app">
    <Breadcrumb
      :title="privacy && privacy.breadcrumb && privacy.breadcrumb.title ? privacy.breadcrumb.title : breadcrumbTitle"
      :backgroundImg="privacy && privacy.breadcrumb && assetUrl+privacy.breadcrumb.image ? assetUrl+privacy.breadcrumb.image : breadcrubBackground"
    ></Breadcrumb>
    <section class="s-privacy">
      <div class="container">
        <div
          class="box-content">
          <div class="title">
            <h2>{{ privacy && privacy.privacy && privacy.privacy.title }}</h2>
          </div>
          <div class="descript" v-html=" privacy && privacy.privacy && privacy.privacy.descript">
            
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import Breadcrumb from '@/core/components/breadcrumb/Breadcrumb.vue';
  export default {
    components: {
      Breadcrumb,
    },

    data() {
      return {
        breadcrumbTitle: 'Privacy',
        breadcrubBackground: require('@/assets/images/breadcrumb/Artboard4.jpg'),
        assetUrl: process.env.VUE_APP_ASSET_URL + '/',
      };
    },

    methods: {
      ...mapActions(['fetchPrivacy']),
    },

    computed: {
      ...mapGetters(['privacy']),
    },

    mounted() {
      this.fetchPrivacy({
        filter: {
          locale: this.$router.currentRoute.params.locale
            ? this.$router.currentRoute.params.locale
            : 'en',
        },
      });
    },

    // seo
    metaInfo () {
      let locale = localStorage.getItem('_locale')
      locale = locale ? JSON.parse(locale) : null
      return {
        title: (this.privacy && this.privacy.breadcrumb && this.privacy.breadcrumb.title
        ? this.privacy.breadcrumb.title
        : 'privacy'),
        titleTemplate:
                '%s | ' +
                (this.$root.general.settings.app_name
                ? this.$root.general.settings.app_name
                : 'Aditi Wesbite'),
        htmlAttrs: {
        lang: locale && locale.title ? locale.title : 'en'
        },
        meta: [
        {
            vmid: 'description',
            name: 'description',
            content: (this.privacy && this.privacy.breadcrumb && this.privacy.breadcrumb.description)
            ? this.privacy.breadcrumb.description
            : (this.$root.general.settings.description
                ? this.$root.general.settings.description
                : '')
        },
        {
            vmid: 'keyword',
            name: 'keyword',
            content: (this.privacy && this.privacy.breadcrumb && this.privacy.breadcrumb.keyword)
            ? this.privacy.breadcrumb.keyword
            : ''
        }
        ]
      }
    },

  };
</script>

<style lang="scss">
  @import '@assets/scss/pages/privacy/privacy';
</style>
