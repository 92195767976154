import api from "@/api";
const state = {
    blog: {},
    blogDetail:{},
    categoryDetail:{},
}
const getters = {
    blog: (state) => state.blog,
    blogDetail: (state) => state.blogDetail,
    categoryDetail: (state) => state.categoryDetail,
};

const actions = {
    async fetchBlog({commit} , params){
        await api.blog.fetchBlog(params).then((response) => {
            commit("setBlog" , response.data);
        })
    },
    async fetchBlogDetail({commit} , params){
        await api.blog.fetchBlogDetail(params).then((response) => {
            commit("setBlogDetail" , response.data);
        })
    },
    async fetchCategoryDetail({commit} , params){
        await api.blog.fetchCategoryDetail(params).then((response) => {
            commit("setCategoryDetail" , response.data);
        })
    },
}
const mutations = {
    setBlog: (state, blog) => (state.blog = blog),
    setBlogDetail: (state, blogDetail) => (state.blogDetail = blogDetail),
    setCategoryDetail: (state, categoryDetail) => (state.categoryDetail = categoryDetail),
};

export default{
    state,
    getters,
    actions,
    mutations,
};