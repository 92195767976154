<template>
<div class="ad-search-box">
  <div class="search-box">
    <div class="title-wrap">
      <div class="icon">
        <b-icon icon="dash-lg"></b-icon>
        <b-icon icon="dot"></b-icon>
      </div>
      <h2 class="title">Search Objects</h2>
    </div>
    <b-form>
      <input type="text">
      <b-button>Search</b-button>
    </b-form>
  </div>
</div>
</template>

<script>
export default {
  name: "AdSearchBox"
}
</script>

<style lang="scss">
@import "@assets/scss/pages/blog/components/search-box.scss";
</style>