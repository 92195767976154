import api from "@/api";

const state = {
  translates: null,
};

const getters = {
  translates: (state) => state.translates,
};

const actions = {
  async fetchTranslates({ commit }, params) {
    await api.translation.fetchTranslates(params).then((response) => {
      if (response.status === 200) {
        if (response.data && response.data.translates.length > 0) {
          const items = {};
          response.data.translates.forEach(function (translate) {
            items[translate.keyword] = translate.label;
          });
          commit("setTranslates", items);
        }
      }
    });
  },
};

const mutations = {
  setTranslates: (state, translates) => (state.translates = translates),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
