<template>
  <div class="ad-blog-post">
    <div class="container">
      <div class="post-wrap">
        <div class="btn-wrap">
          <b-link  class="bus-link">Business</b-link>
          <!-- <b-link class="con-link">Consultant</b-link> -->
        </div>
        <b-card
            no-body
            v-for="(info,id) in post_info"
            :key="id"
        >
          <b-card-body class="custom-card-body">
            <b-card-img :src="info.img"></b-card-img>
            <div class="post-meta">
              <!-- <div class="comment-meta">
                <b-link><b-icon class="chat-icon" icon="chat-fill"></b-icon><span>{{comment}}</span></b-link>
              </div> -->
              <div class="post-date">
                <b-link><b-icon class="calendar-icon" icon="calendar2-week"></b-icon><span>{{date}}</span></b-link>
              </div>
            </div>
            <b-card-text>
              <p class="description">{{info.description}}</p>
            </b-card-text>
            <b-card-title><h3>{{info.title}}</h3></b-card-title>
            <b-card-text>
              <p class="description">{{info.description_01}}</p>
            </b-card-text>
            <div class="quote-container">
              <div class="blog-quote">
                <div class="quote-text">
                  <p>{{info.quote}}</p>
                </div>
              </div>
            </div>
            <b-card-text>
              <p class="description">{{info.description_01}}</p>
            </b-card-text>
          </b-card-body>
        </b-card>
        <!-- <div class="tag">
          <strong>Tags:</strong>
          <div
              class="tag-item"
              v-for="(tag,id) in tag_items"
              :key="id"
          >
            <div class="tag"><b-link :href="tag.link">{{tag.tag}}</b-link></div>
          </div>
        </div> -->
        <!-- <div class="response-post">
          <h2 class="comment-title">{{comment_title}}</h2>
          <AdReplyComment :reply_infos="reply_comment_infos"></AdReplyComment>
        </div> -->
        <!-- <AdPostComment></AdPostComment> -->
      </div>
    </div>
  </div>
</template>

<script>
// import AdReplyComment from "@views/blog/blog-detail/components/ad-reply-comment/AdReplyComment";
// import AdPostComment from "@views/blog/blog-detail/components/ad-post-comment/AdPostComment";
export default {
  name: "AdBlogPost",
  // components:{
  //   AdReplyComment,
  //   AdPostComment
  // },
  props:['post_info'],
  data(){
    return{
      // comment:'4 Comments',
      date:'November 27, 2021',
      quote_img:require('@assets/images/blog-details/icons8-quote-left-100.png'),
      // comment_title:'4 Responses on this post',
      // tag_items:[
      //   {id:1,tag:'charity',link:'#'},
      //   {id:2,tag:'donate',link:'#'},
      // ],
      // reply_comment_infos:[
      //   {
      //     id:1,
      //     title:'Md Ismail Hossain',
      //     img:require('@assets/images/blog-details/reply_profile.jpg'),
      //     date:'November 27, 2021',
      //     time:'6:13 pm',
      //     des:'Lorem ipsum dolor sit amet, consectetur adipisicing elit,' +
      //         ' sed do eiusmod tempor incididunt ut labore et dolore magna. ' +
      //         'Ut enim ad minim veniam, quis nostrud laboris nisi ut aliquip ex ea commodo consequat.'
      //   },
      //   {
      //     id:2,
      //     title:'John',
      //     img:require('@assets/images/blog-details/reply_profile_01.jpg'),
      //     date:'November 28, 2021',
      //     time:'4:26 am',
      //     des:'Lorem ipsum dolor sit amet, consectetur adipisicing elit,' +
      //         ' sed do eiusmod tempor incididunt ut labore et dolore magna. ' +
      //         'Ut enim ad minim veniam, quis nostrud laboris nisi ut aliquip ex ea commodo consequat.'
      //   }
      // ]
    }
  }
}
</script>

<style lang="scss">
@import "@assets/scss/pages/blog/blog-detail/components/blog-post.scss";
</style>