import { ApiService } from '@core/services';
import { api } from '@core/config/api';
export class BlogService extends ApiService {
  endpoint = api.endpoint;

  constructor() {
    super();
  }

  async fetchBlog(params){
    return super.fetch(this.endpoint.blog, {
        params: params,
        retries: 2,
        transformResponse: [
            function (data) {
                if(!data){
                    return {
                        contents: [],
                        sucess: false,
                    };
                }
                const results = JSON.parse(data).data;
                return results;
                
            }
        ]
    });
  }

  async fetchBlogDetail(params){
    return super.fetch(this.endpoint.blogDetail, {
        params: params,
        retries: 2,
        transformResponse: [
            function (data) {
                if(!data){
                    return {
                        contents: [],
                        sucess: false,
                    };
                }
                const results = JSON.parse(data).data;
                console.log(results);
                return results;
                
            }
        ]
    });
  }
  async fetchCategoryDetail(params){
    return super.fetch(this.endpoint.categoryDetail, {
        params: params,
        retries: 2,
        transformResponse: [
            function (data) {
                if(!data){
                    return {
                        contents: [],
                        sucess: false,
                    };
                }
                const results = JSON.parse(data).data;
                console.log(results);
                return results;
                
            }
        ]
    });
  }

}
