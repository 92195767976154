<template>
  <div class="home app">
  <section class="slide">
      <swiper class="swiper" :options="swiperOption"
      v-if="home && home.slider && home.slider.length > 0"
      >
        <swiper-slide v-for="(slide, index) in home.slider" :key="'slide' + index">
          <div class="slider">
            <b-img :src="assetUrl + slide.image" fluid :alt="slide.title"> </b-img>
            <div class="container">
              <div class="content">
                <div class="title">
                  <h1>{{ slide.title }}</h1>
                </div>
                <div class="descript">
                  <p>{{ slide.description }}</p>
                </div>
                <div class="btn-slide">
                  <a :href="slide.first_btn_url" class="btn-main">
                    {{ slide.first_btn_title }}
                    <b-icon-arrow-right-short></b-icon-arrow-right-short
                  ></a>

                  <a :href="slide.second_btn_url" class="btn-main bg-transparent">
                    {{ slide.second_btn_title }}
                    <b-icon-arrow-right-short></b-icon-arrow-right-short
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
  </section>
    <section class="video">
      <div class="container">
        <div class="video-embed">
            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/a_SPQquC61g?autoplay=1&mute=1&loop=1&playlist=a_SPQquC61g&rel=0&modestbranding=1&showinfo=0" title="YouTube video player" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
    </section>

    <section class="service-info sy">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 center">
            <div class="content-center">
              <div class="row justify-content-center" v-if="home && home.serviceList && home.serviceList.length > 0">
                <div
                  class="col-6 "
                  v-for="(service, index) in home.serviceList"
                  :key="'service' + index"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                  data-aos-once="true"
                >
                  <div class="service">
                    <b-link :to="{
                  name: 'ServiceDetail',
                  query: {
                    _post: service.uuid,
                    text: service.slug,
                  },
                }">
                      <div class="image">
                        <img
                          :src="assetUrl + service.icon_image"
                          :alt="service.title"
                          class="img-fluid"
                        />
                      </div>
                      <div class="title">
                        <h4>{{ service.title }}</h4>
                      </div>
                    </b-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 center">
            <div
              class="content-center"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <div>
                <h5 class="head-title">{{ home && home.homeContent && home.homeContent.serviceHeadTitle }}</h5>
                <div class="title">
                  <h2>{{ home && home.homeContent && home.homeContent.service_title}}</h2>
                </div>
                <div class="descript">
                  <p>
                    {{ home && home.homeContent && home.homeContent.service_descript}}
                  </p>
                </div>
                <Button
                  :link="{ name: 'Service' }"
                  class="bg-color"
                  :title=" home && home.homeContent && home.homeContent.btn_title"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="about sy">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 center">
            <div class="content-center">
              <div
                class="box-content"
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <h5 class="head-title"> {{home && home.aboutContent && home.aboutContent.head_title }}</h5>
                <div class="title">
                  <h2>{{home && home.aboutContent && home.aboutContent.title }}</h2>
                </div>
                <div class="descript">
                  <p>
                    {{home && home.aboutContent && home.aboutContent.descript }}
                  </p>
                </div>
                <div>
                <div class="key-point" >
                  <template v-for="(keyWord, index) in home.keyWord" >
                    <div class="item" v-if="index > 2" :key="'keyWord' + index">
                    <div class="image">
                      <img :src="keyWord.icon ? assetUrl + keyWord.icon  : require('@/assets/images/service/value.png') "
                        :alt="keyWord.title" class="img-fluid" />
                    </div>
                    <div class="center content">
                      <div class="content-center">
                        <div class="box-content">
                          <div class="title"><h5>{{keyWord.title}}</h5></div>
                          <div class="des two-line">
                            <p>
                            {{ keyWord.descript}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </template>
                </div>
                </div>
                
                <Button
                  :link="{ name: 'About' }"
                  class="bg-color"
                  title="Learn More"
                ></Button>
              </div>
            </div>
          </div>
          <div class="col-lg-6 center">
            <div class="content-center">
              <div class="image circle-shap" 
              data-aos="zoom-in"
                  data-aos-duration="1000"
                  data-aos-delay='200'
                  data-aos-once="true">
                <img
                :src=" home && home.aboutContent && home.aboutContent.image_about ? home && home.aboutContent && assetUrl + home.aboutContent.image_about : require('@/assets/images/about/about.png')"
                  :alt="home && home.aboutContent && home.aboutContent.title"
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Counter> </Counter>
    <serviceList></serviceList>
  
    
    <AdChooseUs></AdChooseUs>


    <!-- <section class="blog-list sy">
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-lg-4 col-6 mb-4"
            v-for="(blog, index) in blogList"
            :key="'blog' + index"
          >
            <div class="blog">
              <div class="image">
                <b-link :to="{ name: 'BlogDetail' }">
                  <img
                    :src="blog.thubmnail"
                    :alt="blog.title"
                    class="img-fluid"
                  />
                </b-link>
              </div>
              <div class="content">
                <div class="title">
                  <h4>{{ blog.title }}</h4>
                </div>
                <div class="blog-cate">
                  <strong>{{ blog.category }}</strong>
                </div>
              </div>
              <div class="content-hover text-center">
                <b-link :to="{ name: 'BlogDetail' }">
                  <div class="title">
                    <h4>{{ blog.title }}</h4>
                  </div>
                </b-link>
                <div class="des two-line">
                  <p>{{ blog.keyword }}</p>
                </div>
                <Button
                  :link="{ name: 'BlogDetail' }"
                  class="btn-service"
                  title="Read More"
                >
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <section
      class="contact sy"
      :style="{ 'background-image': 'url(' + serviceBackground + ')' }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="box-content">
              <div class="title">
                <h2>{{ home && home.homeContent && home.homeContent.contact_title}}</h2>
              </div>
              <div class="descript">
                <p>
                  {{ home && home.homeContent && home.homeContent.contact_descript}}
                </p>
              </div>
              <div class="key-point">
                <div class="item">
                  <a :href="'tel:'+($root && $root.settings && $root.settings.phone)">
                    <div class="icon-wrap">
                      <b-icon-telephone></b-icon-telephone>
                    </div>
                  </a>
                  <div class="center content">
                    <div class="content-center">
                      <div class="box-content">
                        <div class="title"><h5>Phone Number</h5></div>
                        <div class="des two-line">
                          <p>
                            <strong> {{$root && $root.settings && $root.settings.phone }}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <a :href="'mailto:'+($root && $root.settings && $root.settings.email)">
                    <div class="icon-wrap">
                      <b-icon-envelope></b-icon-envelope>
                    </div>
                  </a>
                  <div class="center content">
                    <div class="content-center">
                      <div class="box-content">
                        <div class="title"><h5>Email Address</h5></div>
                        <div class="des two-line">
                          <p>
                            <strong> {{ $root && $root.settings && $root.settings.email }}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="contact-form">
              <div class="head">
                <div class="head-title"><h5>{{ home && home.homeContent && home.homeContent.form_head_title}}</h5></div>
                <div class="title mb-3"><h2>{{ home && home.homeContent && home.homeContent.form_title}}</h2></div>
              </div>
              <form id="form" novalidate autocomplete="off" autofocus class="needs-validation mt-5" 
                enctype="multipart/form-data" @submit.prevent="onSubmit($event)">
                  <div class="row">
                    <div class="col-12 margin_col" >
                      <input v-model="formRequest.full_name"
                        :class="{ 'is-invalid mb-3': $v.formRequest.full_name.$error}"
                        name="full_name" type="text" class="form-control" id="InputFullName"
                        :placeholder="$root && $root.translates && $root.translates.full_name ? $root.translates.full_name : 'full name'"
                      /> 
                      <div v-if="!$v.formRequest.full_name.required" class="invalid-feedback">
                        {{
                          $root.translates && $root.translates["full_name_validation"] 
                          ? $root.translates["full_name_validation"]
                          : "Please enter full name"
                        }}.
                      </div>
                    </div>
                    <div class="col-12 margin_col">
                      <input v-model="formRequest.email_address"
                        :class="{
                          'is-invalid mb-3': $v.formRequest.email_address.$error,
                        }"
                        type="email" name="email_address" class="form-control" id="InputEmail"
                        :placeholder="$root && $root.translates && $root.translates.email_address ? $root.translates.email_address : 'Email address' " />
                        <div v-if="!$v.formRequest.email_address.required" class="invalid-feedback" >
                          {{
                            $root.translates && $root.translates["email_address_validation"]
                            ? $root.translates["email_address_validation"]
                            : "Please enter email address" 
                          }}.
                        </div> 
                        <div v-else-if="!$v.formRequest.email_address.email_address" class="invalid-feedback" >
                          {{
                            $root.translates && $root.translates["email_address_invalid_validation"]
                              ? $root.translates["email_address_invalid_validation"]
                              : "Please enter invalid email address"
                          }}.
                        </div>
                    </div>
                    <div class="col12 margin_col">
                      <input v-model="formRequest.phone_number"
                        :class="{ 'is-invalid mb-3': $v.formRequest.phone_number.$error}"
                        name="phone_number" type="text" class="form-control" id="InputPhoneNumber"
                        :placeholder="$root && $root.translates && $root.translates.phone_number ? $root.translates.phone_number : 'Phone number' " 
                      />
                      <div v-if="!$v.formRequest.phone_number.required" class="invalid-feedback">
                        {{
                          $root.translates && $root.translates["phone_number_validation"] 
                          ? $root.translates["phone_number_validation"]
                          : "Please enter phone number"
                        }}.
                      </div>
                    </div>
                    <div class="col-12 margin_col">
                      <input v-model="formRequest.subject"
                        :class="{ 'is-invalid mb-3': $v.formRequest.subject.$error}"
                        name="phone_number" type="text" class="form-control" id="InputSubject"
                        :placeholder="$root && $root.translates && $root.translates.subject ? $root.translates.subject : 'subject' " 
                      />
                      <div v-if="!$v.formRequest.subject.required" class="invalid-feedback">
                        {{
                          $root.translates && $root.translates["subject_validation"] 
                          ? $root.translates["subject_validation"]
                          : "Please enter subject"
                        }}.
                      </div>
                    </div>
                    <div class="col-12 margin_col">
                      <textarea v-model="formRequest.message"
                        :class="{
                          'is-invalid mb-3': $v.formRequest.message.$error,
                        }"
                        class="form-control" id="InputMessage" rows="3"
                        :placeholder="$root && $root.translates && $root.translates.message ? $root.translates.message : 'Message' ">
                      </textarea>
                      <div v-if="!$v.formRequest.message.required" class="invalid-feedback">
                        {{  $root.translates && $root.translates["message_validation"]
                            ? $root.translates["message_validation"]
                            : "Please enter your message" }}.
                      </div>
                    </div>
                    <vue-recaptcha 
                      @verify="markRecaptchaAsVerified" 
                      sitekey="6LcJAF8jAAAAAA8so4WVn4QXqExpMSeLdW1fI4Vg">
                    </vue-recaptcha>
                    <div class="invalid-recaptcha">
                      {{ pleaseTickRecaptchaMessage }}
                    </div>
                  </div>
                  <div class="btn-wrap">
                    <button type="submit" class="btn text-uppercase btn-submit btn-white btn-main" >
                    {{home && home.homeContent && home.homeContent.form_btn_title}}<b-icon-arrow-right class="arrow-right"></b-icon-arrow-right>
                    </button>
                  </div>
            </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="testimonail-list sy">
      <div class="container">
        <div class="sy">
          <swiper class="swiper" :options="testimonailSlide">
          <swiper-slide
            v-for="(testimonail, index) in TestimonailList"
            :key="'testimonail' + index"
          >
            <div class="testimonail">
              <div class="icon"><b-icon-chat-quote></b-icon-chat-quote></div>
              <div class="message"><p>{{ testimonail.message}}</p></div>
              <div class="info">
                  <div class="image">
                    <img
                      :src="testimonail.profile"
                      alt=""
                      class="img-fluid"
                    />
                  </div>
                  <div class="center content ">
                    <div class="content-center">
                      <div class="box-content">
                        <div class="title"><h5>{{testimonail.name}}</h5></div>
                        <div class="des two-line">
          
                            {{testimonail.position}}
                         
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        </div>
        <swiper class="swiper" :options="clientSlide">
          <swiper-slide
            v-for="(client, index) in clientList"
            :key="'client' + index"
          >
            <div class="client">
              <b-link :href="client.link">
                <div class="image">
                  <img :src="client.logo" :alt="client.title" class="img-fluid">
                </div>
              </b-link>
            </div>
          </swiper-slide>
          </swiper>
       
      </div>
    </section> -->

    <Testimonail></Testimonail>
  </div>
</template> 

<script>
import Button from '@/core/components/button/Button';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import Counter from '@/components/counter/Counter.vue';
import Testimonail from '@/components/testimonail/Testimonail.vue';
import serviceList from '@/components/service/Service.vue';
import { VueRecaptcha } from 'vue-recaptcha';
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
// import AdHomeTeamMember from './components/ad-home-team-memeber/AdHomeTeamMember.vue';
import AdChooseUs from "@/components/ad-choose-us/AdChooseUs";
import { mapActions, mapGetters } from 'vuex';
import api from "@/api";


export default {
  name: 'Home',

  metaInfo () {
    let locale = localStorage.getItem('_locale')
    locale = locale ? JSON.parse(locale) : null
    return {
      title: (this.home && this.home.breadcrumb && this.home.breadcrumb.title
        ? this.home.breadcrumb.title
        : 'Home'),
      titleTemplate:
              '%s | ' +
              (this.$root.general.settings.app_name
                ? this.$root.general.settings.app_name
                : 'Aditi Wesbite'),
      htmlAttrs: {
        lang: locale && locale.title ? locale.title : 'en'
      },
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: (this.home && this.home.breadcrumb && this.home.breadcrumb.description)
            ? this.home.breadcrumb.description
            : (this.$root.general.settings.description
              ? this.$root.general.settings.description
              : '')
        },
        {
          vmid: 'keyword',
          name: 'keyword',
          content: (this.home && this.home.breadcrumb && this.home.breadcrumb.key_word)
            ? this.home.breadcrumb.key_word
            : ''
        }
      ]
    }
  },

  components: {
    Swiper,
    SwiperSlide,
    Button,
    // AdHomeTeamMember,
    Counter,
    Testimonail,
    serviceList,
    AdChooseUs,
    VueRecaptcha,
  },
  mixins: [validationMixin],
  setup() {
      function toastSuccess(message) {
        this.$toast.success(message);
      }
      function toastWarning(message) {
        this.$toast.warning(message);
      }
      function toastInfo(message) {
        this.$toast.info(message);
      }
      function toastError(message) {
        this.$toast.error(message);
      }
      function toastDefault(message) {
        this.$toast(message);
      }
      return {
        toastDefault,
        toastSuccess,
        toastInfo,
        toastWarning,
        toastError,
      };
  },
  data() {

      return {
        assetUrl: process.env.VUE_APP_ASSET_URL + '/',
        serviceBackground: require('@/assets/images/list-service.png'),
       
        formRequest:{
          full_name: null,
          email_address: null,
          phone_number: null,
          subject: null,
          message: null,
          recaptchaVerified: false,
        },
        pleaseTickRecaptchaMessage:'',

        options: {
          muted: true,
          autoplay: true,
          controls: false,
          loop: true,
        },

      // videoID: 'a_SPQquC61g',
			// height: 500,
			// options: {
			// 	  muted: true,
	    //   	autoplay: true,
			// },
			// playerReady: false,

        // clientList: [
        //   {
        //     title: 'Auto Space',
        //     link: 'www.google.com',
        //     logo: require('@/assets/images/client/Artboard 31.png'),
        //   },
        //   {
        //     title: 'Camsolution',
        //     link: 'www.google.com',
        //     logo: require('@/assets/images/client/Artboard 1.png'),
        //   },
        //   {
        //     title: 'Camsolution',
        //     link: 'www.google.com',
        //     logo: require('@/assets/images/client/Artboard 2.png'),
        //   },
        //   {
        //     title: 'Camsolution',
        //     link: 'www.google.com',
        //     logo: require('@/assets/images/client/Artboard 4.png'),
        //   },
        //   {
        //     title: 'Camsolution',
        //     link: 'www.google.com',
        //     logo: require('@/assets/images/client/Artboard 5.png'),
        //   },
        //   {
        //     title: 'Camsolution',
        //     link: 'www.google.com',
        //     logo: require('@/assets/images/client/Artboard 6.png'),
        //   },
        //   {
        //     title: 'Camsolution',
        //     link: 'www.google.com',
        //     logo: require('@/assets/images/client/Artboard 25.png'),
        //   },
        //   {
        //     title: 'Camsolution',
        //     link: 'www.google.com',
        //     logo: require('@/assets/images/client/Artboard 26.png'),
        //   },
        // ],
        // //Testimonail

        // TestimonailList: [
        //   {
        //     name: 'Tom Jones',
        //     position: 'General Mananger',
        //     message:
        //       'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ',
        //     profile: require('@/assets/images/teams/profile (1).jpg'),
        //   },
        //   {
        //     name: 'Tom Jones',
        //     position: 'General Mananger',
        //     message:
        //       'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ',
        //     profile: require('@/assets/images/teams/profile (2).jpg'),
        //   },
        //   {
        //     name: 'Tom Jones',
        //     position: 'General Mananger',
        //     message:
        //       'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ',
        //     profile: require('@/assets/images/teams/profile (3).jpg'),
        //   },
        //   {
        //     name: 'Tom Jones',
        //     position: 'General Mananger',
        //     message:
        //       'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ',
        //     profile: require('@/assets/images/teams/profile (3).jpg'),
        //   },
        // ],
        // //Counter
        // counterList: [
        //   {
        //     icon: require('@/assets/images/icon/icon-3.png'),
        //     title: 'Project Complete',
        //     count: '100',
        //   },
        //   {
        //     icon: require('@/assets/images/icon/icon-4.png'),
        //     title: 'Expert Consultants',
        //     count: '200',
        //   },
        //   {
        //     icon: require('@/assets/images/icon/icon-2.png'),
        //     title: 'Project Complete',
        //     count: '100',
        //   },
        //   {
        //     icon: require('@/assets/images/icon/icon-1.png'),
        //     title: 'Project Complete',
        //     count: '100',
        //   },
        // ],

        // //Blog
        // blogList: [
        //   {
        //     title: 'Business Knowledge',
        //     category: 'Business',
        //     thubmnail: require('@/assets/images/blog/Artboard 2.jpg'),
        //     keyword:
        //       'Sed ut perspiciatis unde omnis natus error sit voluptatem accusa ntium doloremque laudantium totam rem aperiamea',
        //   },
        //   {
        //     title: 'Relationship Buildup',
        //     category: 'Business Product',
        //     thubmnail: require('@/assets/images/blog/Artboard 3.jpg'),
        //     keyword:
        //       'Sed ut perspiciatis unde omnis natus error sit voluptatem accusa ntium doloremque laudantium totam rem aperiamea',
        //   },
        //   {
        //     title: 'Business Knowledge',
        //     category: 'Business Engineering',
        //     thubmnail: require('@/assets/images/blog/Artboard 4.jpg'),
        //     keyword:
        //       'Sed ut perspiciatis unde omnis natus error sit voluptatem accusa ntium doloremque laudantium totam rem aperiamea',
        //   },
        // ],
        // //service
        // // serviceList: [
        // //   {
        // //     title: 'Web Development',
        // //     icon: require('@/assets/images/service/value.png'),
        // //     shortDescript:
        // //       'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        // //     descript:
        // //       'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        // //     thubmnail: require('@/assets/images/service/service-2.jpg'),
        // //     image: require('@/assets/images/service/service.jpg'),
        // //   },
        // //   {
        // //     title: 'Digital Marketing',
        // //     icon: require('@/assets/images/service/value.png'),
        // //     shortDescript:
        // //       'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        // //     descript:
        // //       'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        // //     thubmnail: require('@/assets/images/service/service-3.jpg'),
        // //     image: require('@/assets/images/service/service.jpg'),
        // //   },
        // //   {
        // //     title: 'Mobile Application',
        // //     icon: require('@/assets/images/service/value.png'),
        // //     shortDescript:
        // //       'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        // //     descript:
        // //       'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        // //     thubmnail: require('@/assets/images/service/service-4.jpg'),
        // //     image: require('@/assets/images/service/service.jpg'),
        // //   },
        // //   {
        // //     title: 'Graphic Design',
        // //     icon: require('@/assets/images/service/value.png'),
        // //     shortDescript:
        // //       'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        // //     descript:
        // //       'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        // //     thubmnail: require('@/assets/images/service/service.jpg'),
        // //     image: require('@/assets/images/service/service.jpg'),
        // //   },
        // // ],

        // //slider data
        // slides: [
        //   {
        //     title: 'Grow Your IT Solutions Business.',
        //     descript: 'We Have 15 Years Of Experience In IT Solutions',
        //     image: require('@/assets/images/slide/Artboard 3.jpg'),
        //     firstButtonName: 'Service we Provide',
        //     firstButtonLink: '#',
        //     secondButtonName: 'Learn More',
        //     secondButtonLink: '#',
        //   },
        //   {
        //     title: 'IT Solutions For  Grow Your Business.',
        //     descript: 'We Have 25 Years Of Experience In IT Solutions',
        //     image: require('@/assets/images/slide/Artboard 4.jpg'),
        //   },
        // ],
        swiperOption: {
          autoplay: {
            delay: 10000,
            disableOnInteraction: false,
          },
        },
      };
    },
    methods: {
      ...mapActions(['fetchHome','fetchCounter']),
      markRecaptchaAsVerified(response) {
        this.formRequest.recaptchaVerified = true;
        this.pleaseTickRecaptchaMessage = '';
      },
      
      onSubmit() {
        if (!this.formRequest.recaptchaVerified) {
          this.pleaseTickRecaptchaMessage = 'Please tick recaptcha';
          return true; 
        }
        this.$root.loading = true;
        this.$root._loading();
        // stop here if form is invalid
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$root.loading = false;
          this.$root._loading();
          this.toastError("Please enter all information that are required.");
          return false;
        }
        api.contact
         .fetchContact(this.getFormData(this.formRequest))
          .then((response) => {
            this.toastSuccess(response.data.message);
            this.$root.loading = false;
            this.$root._loading();
            this.formRequest= {
              full_name: null,
              email_address: null,
              recaptchaVerified: false,
            };
            this.$v.$reset();
            grecaptcha.reset();
          })
          .catch((error) => {
            const hasError = error.response.data.error;
            if (hasError && hasError.validate) {
              const errorValidate = hasError.validate;
              this.onValidateError(errorValidate);
              this.loading = false;
              if (!errorValidate) {
                this.formRequest = {
                  email_address: null,
                  message: null,
                };
                this.toastSuccess(
                  "Thank You for contact us, We will contact back soon."
                );
              }
            } else {
              this.toastError("You cannot sent request successfully!");
            }
          })
          .finally(() => {
            this.$root.loading = false;
            this.$root._loading();
          });
      },
      getFormData(data) {
        const formData = new FormData();
        formData.append("full_name", data.full_name);
        formData.append("email_address", data.email_address);
        formData.append("phone_number", data.phone_number);
        formData.append("subject", data.subject);
        formData.append("message", data.message);
        return formData;
      },   
      onValidateError(errorValidate) {
        if (errorValidate.full_name) {
          this.toastError(errorValidate.full_name[0]);
        }
        if (errorValidate.phone_number) {
          this.toastError(errorValidate.phone_number[0]);
        }
        if (errorValidate.email_address) {
          this.toastError(errorValidate.email_address[0]);
        }
        if (errorValidate.subject) {
          this.toastError(errorValidate.subject[0]);
        }
        if (errorValidate.message) {
          this.toastError(errorValidate.message[0]);
        }
      },
    
  },
    computed: {
      ...mapGetters(['home','counter']),
    },
    validations: {
      formRequest: {
        full_name: { required },
        email_address: { required, email },
        phone_number: { required },
        subject: { required},
        message: { required },
      },


    },
    // computed: {
    //   ...mapGetters(['home','counter']),

    // },
    mounted() {
      // document.getElementsByTagName('iframe')[0].contentWindow.getElementsByClassName('ytp-watch-later-button')[0].style.display = 'none';
      this.fetchCounter({
        filter: {
          locale: this.$router.currentRoute.params.locale
              ? this.$router.currentRoute.params.locale
              : 'en',
        },
      });
      this.fetchHome({
        filter: {
          locale: this.$router.currentRoute.params.locale
              ? this.$router.currentRoute.params.locale
              : 'en',
        },
      });
    
      this.loadYoutubePlayer();
    },
    watch: {
      $route(to, from) {
        if (from.params.locale && to.params.locale !== from.params.locale) {
          this.fetchHome({
            filter: {
              locale: this.$router.currentRoute.params.locale
                  ? this.$router.currentRoute.params.locale
                  : "en",
            },
          });
        }
      },
    },
  };
</script>

<style lang="scss">
  @import '@core/assets/scss/swiper';
  @import '../../assets/scss/pages/home/home';
</style>
