// APP CONFIG - General Project Configuration
// APP CONFIG - General Project Configuration
const env = process.env

export const api = {

  baseURl: env.VUE_APP_API,
  prefix: env.VUE_APP_API_PREFIX ?? '/api/v1',

  endpoint: {
    locales: "get-locales",
    // menus: "get-menus",
    translates: "get-translates",
    setting: "get-setting",
    home: "get-home",
    about: "get-about-content",
    teams: "get-team",
    teamDetail:"get-team-detail",
    component: {
      counter: "get-counter",
      testimonail:'get-testimonial',
      chooseUs:'get-choose-us',
      service: 'get-service',
      serviceDetail: 'get-service-detail',
      faq: 'get-faq',
      
    },
    career: 'get-career',
    careerDetail: 'get-career-detail',
    privacy: 'get-privacy',
    condition: 'get-term-condition',
    sent_contact: "sent-contact",
    get_contact: "get-contact",
    sent_client_mail:"sent-client-mail",

    portfolio: 'get-portfolio',
    portfolioDetail: 'get-portfolio-detail',
    get_project_image:"get-project-image",

    blog:'get-blog',
    blogDetail:'get-blog-detail',
    categoryDetail:'get-category-detail',

    // service: 'get-service',
    // about: 'get-about',
    // activity: 'get-blog'
    // slides: 'get-company-overview',
    // slides: 'get-activities-company',
  }
}
