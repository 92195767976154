<template>
  <div class="wrap">
    <!-- Header -->
    <Header />
    <main class="wrap-content">
      <router-view/>
    </main>
    <!-- Footer -->
    <Footer />
    <div id="loading-bg">

    </div>
  </div>
</template>
<script>
  import Header from './layouts/header/Header.vue'
  import Footer from './layouts/footer/Footer.vue'

  export default {
    name: 'LayoutWeb',
    metaInfo () {
      return {
        title: 'Home page',
        // all titles will be injected into this template
        titleTemplate: '%s | ' + this.$root.general.settings ? this.$root.general.settings.app_name : 'Home',
        meta: [
          { vmid: 'description', name: 'description', content: this.$root.general.settings.description ? this.$root.general.settings.description : '' }
        ],
        link: [
          { vmid: 'icon', rel: 'icon', href: this.$root.general.settings.favicon },
          { vmid: 'shortcut-icon', rel: 'shortcut-icon', href: this.$root.general.settings.favicon },
          { vmid: 'apple-touch-icon', rel: 'apple-touch-icon', href: this.$root.general.settings.favicon },
          { vmid: 'apple-touch-icon-precomposed', rel: 'apple-touch-icon-precomposed', href: this.$root.general.settings.favicon }
        ]
      }
    },
    components: {
      Header,
      Footer
    }

  };
</script>


<style>
@font-face{
    font-family: 'aditi-font';
    font-style: normal;
  
    src: url('./fonts/Raleway-Regular.ttf') format('truetype'),
    url('./fonts/Raleway-Regular.woff2') format("woff2"),
    url('./fonts/Raleway-Regular.woff') format("woff");
}

.wrap{
  overflow-x: hidden;
}
</style>
