<template>
<div class="card-post">
  <b-card v-for="(info,id) in categoryDetail && categoryDetail.category && categoryDetail.category.blogs"
      :key="id" class="mb-2"
  >
    <b-link>
      <b-card-img :src="assetUrl+info.image" class="img-fluid card-img-top" :alt="info.title"></b-card-img>
    </b-link>
    <b-card-body>
      <b-card-title><h1><b-link>{{info.title}}</b-link></h1></b-card-title>
      <div class="post-meta">
        <div class="post-date">
          <b-link>
            <b-icon class="calendar-icon" icon="calendar2-week"></b-icon>
            <span>{{info.date}}</span>
          </b-link>
        </div>
      </div>
      <b-card-text>
        <b-link>
          <p class="four-line" v-html="info.first_description"></p>
        </b-link>
      </b-card-text>
    </b-card-body>
    <b-card-footer>
      <div class="profile">
        <div class="blog-author-meta">
          <div class="author-thumbnail">
            <b-img :src="assetUrl+info.profile"  :alt="info.writer" class="img-fluid"></b-img>
          </div>
          <div class="blog-author-name">
            <h5>by techex-admin</h5>
          </div>
        </div>
        <div class="post-read-more">
          <b-link
           :to="{
            name: 'BlogDetail',
            query: {
              _post: info.uuid,
              text: info.slug,
            },
          }">Read More<b-icon class="arrow-right" icon="arrow-right"></b-icon></b-link>
        </div>
      </div>
    </b-card-footer>
  </b-card>
</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AdCardPost",
  props:['post_info'],
  data (){
    return{
      date:'August 6, 2022',
      assetUrl: process.env.VUE_APP_ASSET_URL + '/',
      avatar_default:{
        type:String,
        require:true,
        default: require('@assets/images/blog/blog-avatar.jpg')
      }
    }
  },
  methods: {
    ...mapActions(["fetchCategoryDetail"]),
  },
  computed: {
    ...mapGetters(["categoryDetail"]),
  },
  mounted() {
     this.fetchCategoryDetail({
        filter: {
          locale: this.$router.currentRoute.params.locale
            ? this.$router.currentRoute.params.locale
            : 'en',
        },
        uuid: this.$router.currentRoute.query._post
          ? this.$router.currentRoute.query._post
          : null,
          
      });
  },
  watch: {
    $route(to, from) {
      if (from.params.locale && to.params.locale !== from.params.locale) {
        this.fetchCategoryDetail({
            filter: {
              locale: this.$router.currentRoute.params.locale
                ? this.$router.currentRoute.params.locale
                : 'en',
            },
            uuid: this.$router.currentRoute.query
              ? this.$router.currentRoute.query._post
              : null,
          });
        
      } else if (from.query._post !== to.query._post) {
        this.fetchCategoryDetail({
            filter: {
              locale: this.$router.currentRoute.params.locale
                ? this.$router.currentRoute.params.locale
                : 'en',
            },
            uuid: this.$router.currentRoute.query
              ? this.$router.currentRoute.query._post
              : null,
          });
      }
    },
  },
}
</script>

<style lang="scss">
@import "@assets/scss/pages/blog/components/card-post.scss";
</style>