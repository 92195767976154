<template>
  <div class="ad-tag-box">
    <div class="popular-tag">
      <div class="title-wrap">
        <div class="icon">
          <b-icon icon="dash-lg"></b-icon>
          <b-icon icon="dot"></b-icon>
        </div>
        <h2 class="title">Popular Tags</h2>
      </div>
      <div
          class="tag-item"
          v-for="(tag,id) in tag_items"
          :key="id"
      >
        <div class="tag"><b-link :href="tag.link">{{tag.tag}}</b-link></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdTagBox",
  props:['tag_items']
}
</script>

<style lang="scss">
@import "@assets/scss/pages/blog/components/tag-box.scss";
</style>