<template>
  <div>
    <Breadcrumb :title="portfolio && portfolio.breadcrumb && portfolio.breadcrumb.title ? portfolio.breadcrumb.title : breadcrumbTitle" 
    :backgroundImg="portfolio && portfolio.breadcrumb && assetUrl + portfolio.breadcrumb.image ? assetUrl + portfolio.breadcrumb.image: breadcrubBackground">
    </Breadcrumb>

    <section class="portfolio-list sy">
      <div class="container">
        <div class="head mb-3">
          <h5 class="head-title">{{$root.translates && $root.translates.recent_project ? $root.translates.recent_project : "Our Recent Project" }}</h5>
          <div class="title">
            <h1>{{$root.translates && $root.translates.lastest_student ? $root.translates.lastest_student : "Latest Case Studies" }}</h1>
          </div>
        </div>

        <div>
          <b-tabs v-if=" portfolio && portfolio.service && portfolio.service.length > 0 ">
            <div
              v-for="(item, index) in portfolio.service"
              :key="'item' + index"
            >
              <div v-if="item.project.length>0">
                <b-tab :title="item.title" class="nav-project">
                  <div class="row py-3 justify-content-center">
                    <div class="col-lg-4 col-6"  v-for="project in item.project" :key="project.id" >
                      <div class="project">
                        <b-link
                          :to="{
                            name: 'PortfolioDetail',
                            query: {
                              _post: project.uuid,
                              text: project.slug,
                            },
                          }"
                        >
                          <div class="image">
                            <img :src="assetUrl + project.image" :alt="project.title" class="img-fluid" />
                          </div>
                        </b-link>
                        <div class="info">
                          <b-link :to="{
                            name: 'PortfolioDetail',
                            query: {
                              _post: project.uuid,
                              text: project.slug,
                            },
                          }">
                            <div class="title"> <h4>{{ project.title }}</h4> </div>
                          </b-link>
                          <div class="client"> <strong>{{ project.client }}</strong> </div>
                          <Button
                            :link="{
                            name: 'PortfolioDetail',
                            query: {
                              _post: project.uuid,
                              text: project.slug,
                            },
                          }"
                            class="btn-service"
                            title="Get Free Quote"
                          ></Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </div>
            </div>
          </b-tabs>
        </div>
      </div>
    </section>
    <Testimonail :Testimonails="TestimonailList" :Clients="clientList">
    </Testimonail>
  </div>
</template>
<script>
  import Testimonail from '@/components/testimonail/Testimonail.vue';
  import Breadcrumb from '@/core/components/breadcrumb/Breadcrumb.vue';
  import Button from '@/core/components/button/Button.vue';
  import { mapActions, mapGetters } from 'vuex';
  export default {
    name: 'Portfolio',
    components: {
      Breadcrumb,
      Testimonail,
      Button,
    },
    data() {
      return {
        assetUrl: process.env.VUE_APP_ASSET_URL + '/',
        breadcrumbTitle: 'Portfolio',
        breadcrubBackground: require('@/assets/images/breadcrumb/Artboard4.jpg'),
        // clientList: [
        //   {
        //     title: 'Auto Space',
        //     link: 'www.google.com',
        //     logo: require('@/assets/images/client/Artboard 31.png'),
        //   },
        //   {
        //     title: 'Camsolution',
        //     link: 'www.google.com',
        //     logo: require('@/assets/images/client/Artboard 1.png'),
        //   },
        //   {
        //     title: 'Camsolution',
        //     link: 'www.google.com',
        //     logo: require('@/assets/images/client/Artboard 2.png'),
        //   },
        //   {
        //     title: 'Camsolution',
        //     link: 'www.google.com',
        //     logo: require('@/assets/images/client/Artboard 4.png'),
        //   },
        //   {
        //     title: 'Camsolution',
        //     link: 'www.google.com',
        //     logo: require('@/assets/images/client/Artboard 5.png'),
        //   },
        //   {
        //     title: 'Camsolution',
        //     link: 'www.google.com',
        //     logo: require('@/assets/images/client/Artboard 6.png'),
        //   },
        //   {
        //     title: 'Camsolution',
        //     link: 'www.google.com',
        //     logo: require('@/assets/images/client/Artboard 25.png'),
        //   },
        //   {
        //     title: 'Camsolution',
        //     link: 'www.google.com',
        //     logo: require('@/assets/images/client/Artboard 26.png'),
        //   },
        // ],
        // //Testimonail

        // TestimonailList: [
        //   {
        //     name: 'Tom Jones',
        //     position: 'General Mananger',
        //     message:
        //       'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ',
        //     profile: require('@/assets/images/teams/profile (1).jpg'),
        //   },
        //   {
        //     name: 'Tom Jones',
        //     position: 'General Mananger',
        //     message:
        //       'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ',
        //     profile: require('@/assets/images/teams/profile (2).jpg'),
        //   },
        //   {
        //     name: 'Tom Jones',
        //     position: 'General Mananger',
        //     message:
        //       'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ',
        //     profile: require('@/assets/images/teams/profile (3).jpg'),
        //   },
        //   {
        //     name: 'Tom Jones',
        //     position: 'General Mananger',
        //     message:
        //       'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ',
        //     profile: require('@/assets/images/teams/profile (3).jpg'),
        //   },
        // ],
        // ProjectType: [
        //   {
        //     title: 'Web Development',
        //     project: [
        //       {
        //         title: 'Ecommerce Website',
        //         client: 'We Mall',
        //         date: '27 Feb 2022',
        //         image: require('@/assets/images/service/service-2.jpg'),
        //       },
        //       {
        //         title: 'Ecommerce Website',
        //         client: 'Auto Space',
        //         date: '27 Feb 2022',
        //         image: require('@/assets/images/service/service-3.jpg'),
        //       },
        //       {
        //         title: 'Ecommerce Website',
        //         client: 'Auto Space',
        //         date: '27 Feb 2022',
        //         image: require('@/assets/images/service/service-3.jpg'),
        //       },
        //       {
        //         title: 'News Website',
        //         client: 'Politik Coffee',
        //         date: '27 Feb 2022',
        //         image: require('@/assets/images/service/service-4.jpg'),
        //       },
        //     ],
        //   },
        //   {
        //     title: 'Mobile Application',
        //     project: [
        //       {
        //         title: 'Delivery App',
        //         client: 'Power Express',
        //         date: '27 Feb 2022',
        //         image: require('@/assets/images/service/service-3.jpg'),
        //       },
        //       {
        //         title: 'Ecommerce App',
        //         client: 'We Food',
        //         date: '27 Feb 2022',
        //         image: require('@/assets/images/service/service-4.jpg'),
        //       },
        //       {
        //         title: 'News App',
        //         client: 'Politik Coffee',
        //         date: '27 Feb 2022',
        //         image: require('@/assets/images/service/service-2.jpg'),
        //       },
        //     ],
        //   },
        //   {
        //     title: 'Digital Marketing',
        //     project: [
        //       {
        //         title: 'Manage Facebook page',
        //         client: 'High Five Printing',
        //         date: '27 Feb 2022',
        //         image: require('@/assets/images/service/service-2.jpg'),
        //       },
        //       {
        //         title: 'Manage Facebook',
        //         client: 'Zong Ying School',
        //         date: '27 Feb 2022',
        //         image: require('@/assets/images/service/service-3.jpg'),
        //       },
        //       {
        //         title: 'News App',
        //         client: 'Politik Coffee',
        //         date: '27 Feb 2022',
        //         image: require('@/assets/images/service/service-4.jpg'),
        //       },
        //     ],
        //   },
        // ],
      };
    },
    methods: {
      ...mapActions(['fetchPortfolio']),
    },
    computed: {
      ...mapGetters(['portfolio']),
    },
    mounted() {
      this.fetchPortfolio({
        filter: {
          locale: this.$router.currentRoute.params.locale
            ? this.$router.currentRoute.params.locale
            : 'en',
        },
      });
    },
    watch: {
      $route(to, from) {
        if (from.params.locale && to.params.locale !== from.params.locale) {
          this.fetchPortfolio({
            filter: {
              locale: this.$router.currentRoute.params.locale
                ? this.$router.currentRoute.params.locale
                : 'en',
            },
          });
        }
      },
    },

    // seo
    metaInfo () {
      let locale = localStorage.getItem('_locale')
      locale = locale ? JSON.parse(locale) : null
      return {
        title: (this.portfolio && this.portfolio.breadcrumb && this.portfolio.breadcrumb.title
        ? this.portfolio.breadcrumb.title
        : 'Team detail'),
        titleTemplate:
                '%s | ' +
                (this.$root.general.settings.app_name
                ? this.$root.general.settings.app_name
                : 'Aditi Wesbite'),
        htmlAttrs: {
        lang: locale && locale.title ? locale.title : 'en'
        },
        meta: [
        {
            vmid: 'description',
            name: 'description',
            content: (this.portfolio && this.portfolio.breadcrumb && this.portfolio.breadcrumb.description)
            ? this.portfolio.breadcrumb.description
            : (this.$root.general.settings.description
                ? this.$root.general.settings.description
                : '')
        },
        {
            vmid: 'keyword',
            name: 'keyword',
            content: (this.portfolio && this.portfolio.breadcrumb && this.portfolio.breadcrumb.keyword)
            ? this.portfolio.breadcrumb.keyword
            : ''
        }
        ]
      }
    },

  };
</script>
<style lang="scss">
  @import '@assets/scss/pages/portfolio.scss';
</style>
