import api from "@/api";

const state = {
  condition: {},
};

const getters = {
   condition: (state) => state.condition,
};

const actions = {
  async fetchCondition({ commit }, params) {
    await api.condition.fetchCondition(params).then((response) => {
      commit("setCondition", response.data);
    });
  },
};

const mutations = {
  setCondition: (state, condition) => (state.condition = condition),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
