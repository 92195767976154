var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"career-page"},[_c('section',{staticClass:"section-career"},[_c('div',{staticClass:"container"},[(_vm.career && _vm.career.careerList && _vm.career.careerList.length > 0)?_c('div',{staticClass:"row form-gorup sy"},_vm._l((_vm.career.careerList),function(item,index){return _c('div',{key:'item' + index,staticClass:"col-sm-6 center"},[_c('div',{staticClass:"content-center"},[_c('div',{staticClass:"box-content w-100"},[_c('div',{staticClass:"content"},[_c('h5',{staticClass:"head-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"title"},[_c('router-link',{attrs:{"to":{ name: 'CareerDetail' }}},[_c('h4',[_vm._v(_vm._s(item.position))])])],1),_c('div',{staticClass:"descript"},[_c('p',{staticClass:"three-line"},[_vm._v(" "+_vm._s(item.short_description)+" ")])])]),_c('div',{staticClass:"form-icon"},[_c('hr'),_c('div',{staticClass:"main d-flex justify-content-between"},[_c('div',{staticClass:"content"},[_c('p',{staticClass:"mb-2 text-uppercase"},[_c('b-icon',{attrs:{"icon":"geo-alt-fill"}}),_vm._v("   Location ")],1),_c('h6',{staticClass:"location"},[_vm._v(_vm._s(item.location))])]),_c('div',{staticClass:"content"},[_c('p',{staticClass:"mb-2 text-uppercase"},[_c('b-icon',{attrs:{"icon":"briefcase-fill"}}),_vm._v("   How Work ")],1),_c('h6',{staticClass:"how-work"},[_vm._v(_vm._s(item.how_work))])])]),_c('hr',{staticClass:"mt-2"})]),_c('div',{staticClass:"group mt-4 d-flex justify-content-between"},[_c('div',{staticClass:"date d-flex align-items-center"},[_c('div',{staticClass:"elementor-widget-container"},[_vm._v(" "+_vm._s(item.post_date)+" ")])]),_c('div',{staticClass:"form-link"},[_c('router-link',{staticClass:"btn-main",attrs:{"to":{
                      name: 'CareerDetail',
                      query: {
                        _post: item.uuid,
                        text: item.slug,
                      },
                    }}},[_vm._v(" Apply "),_c('b-icon-arrow-right-short')],1)],1)])])])])}),0):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }