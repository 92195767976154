var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"service-list sy",style:({ 'background-image': 'url(' + _vm.serviceBackground + ')' })},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"head text-center mb-4",attrs:{"data-aos":"zoom-in","data-aos-duration":"1000","data-aos-delay":"200"}},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v(_vm._s(_vm.$root.translates && _vm.$root.translates.sol_business ? _vm.$root.translates.sol_business : "Solution for Business"))])])]),(_vm.home && _vm.home.serviceList && _vm.home.serviceList.length > 0)?_c('div',{staticClass:"row justify-content-center"},_vm._l((_vm.home.serviceList),function(service,index){return _c('div',{key:'service' + index,staticClass:"col-lg-4 col-6 mb-4",attrs:{"id":"service","data-aos":"fade-up","data-aos-duration":"1000","data-aos-delay":"200","data-aos-once":"true"}},[_c('div',{staticClass:"service-item"},[_c('div',{staticClass:"image"},[_c('b-link',{attrs:{"to":{
              name: 'ServiceDetail',
              query: {
                _post: service.uuid,
                text: service.slug,
              },
            }}},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.assetUrl + service.thumbnail,"alt":service.title}})])],1),_c('div',{staticClass:"content text-center"},[_c('div',{staticClass:"title one-line"},[_c('h4',[_vm._v(_vm._s(service && service && service.title? service.title: ""))])]),_c('div',{staticClass:"short one-line"},[_vm._v(_vm._s(service.shortDescript))]),_c('Button',{staticClass:"btn-service",attrs:{"link":{
              name: 'ServiceDetail',
              query: {
                _post: service.uuid,
                text: service.slug,
                },
               },"title":"Learn More"}})],1)])])}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }