var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-detail sy"},[_c('section',{staticClass:"form-section"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-5 col-md-4 flex-xxl-row-reverse"},[_c('section',{staticClass:"section-contact"},[_c('b-navbar-nav',{staticClass:"text nav"},_vm._l((_vm.home && _vm.home.serviceList),function(service,index){return _c('b-nav-item',{key:'service' + index,attrs:{"to":{
                  name: 'ServiceDetail',
                  query: {
                    _post: service.uuid,
                    text: service.slug,
                  },
                }}},[_vm._v(" "+_vm._s(service.title)+" ")])}),1),_c('div',{staticClass:"form-contact"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$root.translates && _vm.$root.translates.contact_us ? _vm.$root.translates.contact_us : "Contact Us"))]),_c('input',{staticClass:"email",attrs:{"type":"text","name":"name","aria-required":"true","aria-invalid":"false","placeholder":"Full Name"}}),_c('input',{staticClass:"email",attrs:{"type":"number","name":"email-707","aria-required":"true","aria-invalid":"false","placeholder":"Phone"}}),_c('input',{staticClass:"email",attrs:{"type":"email","name":"email-707","aria-required":"true","aria-invalid":"false","placeholder":"Email"}}),_c('textarea',{staticClass:"textarea",attrs:{"name":"textarea-339","aria-invalid":"false","placeholder":"Write Message"}}),_c('Button',{staticClass:"bg-color",attrs:{"link":"#","title":"Send Message"}})],1)],1)]),_c('div',{staticClass:"col-sm-7 col-md-8"},[_c('section',{staticClass:"section-service"},[_c('div',{staticClass:"form-service"},[_c('div',{staticClass:"image"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.serviceDetail &&
                    _vm.serviceDetail.service &&
                    _vm.assetUrl + _vm.serviceDetail.service.thumbnail,"alt":_vm.serviceDetail &&
                    _vm.serviceDetail.service &&
                    _vm.serviceDetail.service.title}})]),_c('div',{staticClass:"content-center"},[_c('div',{staticClass:"box-content"},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v(" "+_vm._s(_vm.serviceDetail && _vm.serviceDetail.service && _vm.serviceDetail.service.title)+" ")])]),_c('div',{staticClass:"descript"},[_c('p',[_vm._v(" "+_vm._s(_vm.serviceDetail && _vm.serviceDetail.service && _vm.serviceDetail.service.shortDescript)+" ")])])])]),_c('div',{staticClass:"thumbnail"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.serviceDetail &&
                    _vm.serviceDetail.service &&
                    _vm.assetUrl + _vm.serviceDetail.service.image,"alt":_vm.serviceDetail &&
                    _vm.serviceDetail.service &&
                    _vm.serviceDetail.service.title}})]),_c('div',{staticClass:"text-detail",domProps:{"innerHTML":_vm._s(
                  _vm.serviceDetail &&
                  _vm.serviceDetail.service &&
                  _vm.serviceDetail.service.Descript
                )}})])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }