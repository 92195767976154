import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const mainComponent = { template: "<router-view></router-view>" };
const locale = localStorage.getItem("_locale");

const routes = [
  {
    path: "/",
    redirect:
        locale && JSON.parse(locale)
            ? JSON.parse(locale).title
                ? JSON.parse(locale).title
                : "en"
            : "en",
            
  },
  {
    path: "/:locale",
    component: mainComponent,
    children: [
      {
        path: "",
        name: "Home",
         //component: () => import("../views/home-new/Home.vue"), //new route
         component: () => import("../views/home/Home.vue"),  //old route
      },
      {
        path: "service",
        name: "Service",
        component: () => import("../views/service/Service.vue"),
      },
      {
        path: "service-detail",
        name: "ServiceDetail",
        component: () => import("../views/service-detail/ServiceDetail.vue"),
      },
      {
        path: "about",
        name: "About",
        component: () => import("../views/about/About.vue"),
      },
      {
        path: "blog",
        name: "Blog",
        component: () => import("../views/blog/Blog.vue"),
      },
      {
        path: "solution-detail",
        name: "SolutionDetail",
        component: () => import("@views/blog/blog-detail/BlogDetail.vue"),
      },
      {
        path: "consultant",
        name: "Consultant",
        component: () => import("../views/consultant/Consultant.vue"),
      },
      {
        path: "portfolio",
        name: "Portfolio",
        component: () => import("../views/portfolio/Portfolio.vue"),
      },
      {
        path: "portfolio-detail",
        name: "PortfolioDetail",
        component: () => import("../views/portfolio/PortfolioDetail.vue"),
      },
      {
        path: "team-detail",
        name: "team-detail",
        component: () => import("../views/team-detail/TeamDetail"),
      },
      {
        path: "team",
        name: "team",
        component: () => import("../views/team/Team"),
      },

      {
        path: "privacy",
        name: "Privacy",
        component: () => import("../views/privacy/Privacy.vue"),
      },
      {
        path: "condition",
        name: "Condition",
        component: () => import("../views/condition/Condition.vue"),
      },
      {
        path: "career",
        name: "Career",
        component: () => import("../views/career/Career.vue"),
      },
      {
        path: "career-detail",
        name: "CareerDetail",
        component: () => import("../views/career/CareerDetail.vue"),
      },
      {
        path: "faq",
        name: "Faq",
        component: () => import("../views/faq/Faq"),
      },
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.matched.some((m) => m.meta.disableScroll)) return;
    if (to.hash) {
      return {
        selector: to.hash,
        // , offset: { x: 0, y: 10 }
        behavior: "smooth",
      };
    }
    const position = {
      x: 0,
      y: 0,
    };

    if (savedPosition) {
      position.x = savedPosition.x;
      position.y = savedPosition.y;
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(position);
      }, 400);
    });
  },
});

// check route that need authenticate
router.beforeEach((to, from, next) => {
  const appLoading = document.getElementById("loading-bg");
  let locale = localStorage.getItem("_locale");
  locale = locale ? JSON.parse(locale) : null;
  if (appLoading) {
    appLoading.style.display = "block";
  }
  // if (to.params.locale && to.params.locale !== locale.title) {
  //   next({ name: to.name, params: { locale: locale.title } });
  // }
  next();
});
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
