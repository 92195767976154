<template>
<div class="ad-latest-post">
  <div class="title-wrap">
    <div class="icon">
      <b-icon icon="dash-lg"></b-icon>
      <b-icon icon="dot"></b-icon>
    </div>
    <h1 class="title">Latest Posts</h1>
  </div>
  <div class="post-wrap">
    <div class="post-items"
        v-for="(info, id) in blog.blogLatestTen"
        :key="id"
    >
    <b-link :to="{
        name: 'SolutionDetail',
        query: {
          text: info.slug,
        },
      }">
      <div class="profile">
        <div class="images-wrap">
          <b-link class="images">
            <b-card-img :src="assetUrl+info.image" :alt="info.writer" class="rounded-0 img-fluid"></b-card-img>
          </b-link>
        </div>
        <div class="post-text">
          <p class="post-title">{{info.title}}</p>
          <span><b-icon class="calendar-icon" icon="calendar2-week"></b-icon>{{info.date}}</span>
        </div>
      </div>
    </b-link>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AdLatestPost",
  props:['post_items'],
  data () {
    return{
      assetUrl: process.env.VUE_APP_ASSET_URL + '/',

    }
  },
  methods: {
    
    ...mapActions(["fetchBlog","fetchBlogDetail"]),
    

  },
  computed: {
    ...mapGetters(["blog","blogDetail"]),
  },
  mounted() {
    this.fetchBlog({
      filter: {
        locale: this.$router.currentRoute.params.locale
          ? this.$router.currentRoute.params.locale
          : "en",
      },
    });

    this.fetchBlogDetail({
        filter: {
          locale: this.$router.currentRoute.params.locale
            ? this.$router.currentRoute.params.locale
            : 'en',
        },
        slug: this.$router.currentRoute.query.text
          ? this.$router.currentRoute.query.text
          : null,
        // uuid: this.$router.currentRoute.query._post
        //   ? this.$router.currentRoute.query._post
        //   : null,
        
    });
    
  },
  watch: {
    $route(to, from) {
      if (from.params.locale && to.params.locale !== from.params.locale) {
        this.fetchBlogDetail({
          filter: {
            locale: this.$router.currentRoute.params.locale
              ? this.$router.currentRoute.params.locale
              : "en",
          },
          slug: this.$router.currentRoute.query
            ? this.$router.currentRoute.query.text
            : null,
        });
        this.fetchBlog({
          filter: {
            locale: this.$router.currentRoute.params.locale
                ? this.$router.currentRoute.params.locale
                : "en",
          },
        });
      } else if (from.query.text !== to.query.text) {
        this.fetchBlogDetail({
          filter: {
            locale: this.$router.currentRoute.params.locale
              ? this.$router.currentRoute.params.locale
              : "en",
          },
          slug: to.query && to.query.text ? to.query.text : null,
        });
        this.fetchBlog({
          filter: {
            locale: this.$router.currentRoute.params.locale
              ? this.$router.currentRoute.params.locale
              : "en",
          },
          slug: to.query && to.query.text ? to.query.text : null,
        });
      }
    },
  },
}
</script>

<style lang="scss">
@import "@assets/scss/pages/blog/blog-detail/components/latest-post.scss";
</style>