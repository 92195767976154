<template>
    <div class="blog-detail">
      <breadcrumb
          v-bind:backgroundImg="blogDetail && blogDetail.blog && assetUrl+blogDetail.blog.breadcrumb_image ? assetUrl+blogDetail.blog.breadcrumb_image : bg"
          v-bind:title="blogDetail && blogDetail.blog && blogDetail.blog.title ? blogDetail.blog.title : 'Blog Detail'"
      ></breadcrumb>
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <!-- <AdBlogPost v-bind:post_info="post_infos"></AdBlogPost> -->
            <div class="ad-blog-post">
                <div class="container">
                  <div class="post-wrap">
                    <!-- <div class="btn-wrap">
                      <b-link  class="bus-link">Business</b-link>
                    </div> -->
                    <b-card no-body >
                      <b-card-body class="custom-card-body">
                        <b-card-img 
                          :src="blogDetail && blogDetail.blog && assetUrl+blogDetail.blog.image" 
                          :alt="blogDetail && blogDetail.blog && blogDetail.blog.title">
                        </b-card-img>
                        <div class="post-meta d-flex justify-content-between">
                          <div class="post-date">
                            <b-link><b-icon class="calendar-icon" icon="calendar2-week"></b-icon>
                              <span>{{blogDetail && blogDetail.blog && blogDetail.blog.date}}</span>
                            </b-link>
                          </div>
                          <div class="social-share">
                            <div class="share-fb">
                              <ShareNetwork
                                network="facebook"
                                :url="`${this.urlWeb}en/solution-detail?text=${this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.slug}`"
                                :title="blogDetail && blogDetail.blog && blogDetail.blog.title"
                                :description="blogDetail && blogDetail.blog && blogDetail.blog.short_description"
                                hashtags="ADITI">
                                <b-icon icon="facebook"></b-icon>
                              </ShareNetwork>
                            </div>
                            <div class="share-linkin">
                              <ShareNetwork
                                network="linkedin"
                                :url="`${this.urlWeb}en/solution-detail?text=${this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.slug}`"
                                :title="blogDetail && blogDetail.blog && blogDetail.blog.title"
                                :description="blogDetail && blogDetail.blog && blogDetail.blog.short_description">
                                <b-icon icon="linkedin"></b-icon>
                              </ShareNetwork>
                            </div>
                           <div class="share-twitter">
                              <ShareNetwork
                                network="twitter"
                                :url="`${this.urlWeb}en/solution-detail?text=${this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.slug}`"
                                :title="blogDetail && blogDetail.blog && blogDetail.blog.title"
                                :description="blogDetail && blogDetail.blog && blogDetail.blog.short_description"
                                twitter-user="ADITI">
                                <b-icon icon="twitter"></b-icon>
                              </ShareNetwork>
                           </div>
                            <div class="share-telegram">
                              <ShareNetwork
                                network="telegram"
                                :url="`${this.urlWeb}en/solution-detail?text=${this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.slug}`"
                                :title="blogDetail && blogDetail.blog && blogDetail.blog.title"
                                :description="blogDetail && blogDetail.blog && blogDetail.blog.short_description">
                                <b-icon icon="telegram"></b-icon>
                              </ShareNetwork>
                            </div>
                            <!-- <b-dropdown id="dropdown-right" right text="Right align" variant="white">
                              <template #button-content>
                                <b-icon icon="share" aria-label="Help"></b-icon>
                              </template>
                              <b-dropdown-item>
                                <ShareNetwork
                                  network="facebook"
                                  :url="`http://aditi-dist.web/en/solution-detail?text=${this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.slug}`"
                                  :title="blogDetail && blogDetail.blog && blogDetail.blog.title"
                                  :description="blogDetail && blogDetail.blog && blogDetail.blog.short_description"
                                  hashtags="ADITI">
                                  <div class="d-flex">
                                    <b-icon icon="facebook" variant="primary"></b-icon><p class="text-fb ms-3">Facebook</p>
                                  </div>
                                </ShareNetwork>
                              </b-dropdown-item>
                              <b-dropdown-item>
                                <ShareNetwork
                                  network="linkedin"
                                  :url="`http://aditi-dist.web/en/solution-detail?text=${this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.slug}`"
                                  :title="blogDetail && blogDetail.blog && blogDetail.blog.title"
                                  :description="blogDetail && blogDetail.blog && blogDetail.blog.short_description">
                                <div class="d-flex">
                                  <b-icon icon="linkedin" variant="primary"></b-icon><p class="text-linkedin ms-3">Linkedin</p>
                                </div>
                                </ShareNetwork>
                              </b-dropdown-item>
                              <b-dropdown-item>
                                <ShareNetwork
                                  network="twitter"
                                  :url="`http://aditi-dist.web/en/solution-detail?text=${this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.slug}`"
                                  :title="blogDetail && blogDetail.blog && blogDetail.blog.title"
                                  :description="blogDetail && blogDetail.blog && blogDetail.blog.short_description"
                                  twitter-user="ADITI">
                                <div class="d-flex">
                                  <b-icon icon="twitter" variant="primary"></b-icon><p class="text-twitter ms-3">Twitter</p>
                                </div>
                                </ShareNetwork>
                              </b-dropdown-item>
                              <b-dropdown-item>
                                <ShareNetwork
                                  network="telegram"
                                  :url="`http://aditi-dist.web/en/solution-detail?text=${this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.slug}`"
                                  :title="blogDetail && blogDetail.blog && blogDetail.blog.title"
                                  :description="blogDetail && blogDetail.blog && blogDetail.blog.short_description">
                                  <div class="d-flex">
                                    <b-icon icon="telegram" variant="primary"></b-icon><p class="text-telegram ms-3">Telegram</p>
                                  </div>
                                </ShareNetwork>
                              </b-dropdown-item>
                            </b-dropdown> -->
                          </div>
                        </div>
                        <b-card-text>
                          <div v-html ="blogDetail && blogDetail.blog && blogDetail.blog.first_description"></div>
                        </b-card-text>
                        <div class="quote-container">
                          <div class="blog-quote">
                            <div class="quote-text">
                              <p>{{blogDetail && blogDetail.blog && blogDetail.blog.short_description}}</p>
                            </div>
                          </div>
                        </div>
                        <b-card-text>
                          <div v-html="blogDetail && blogDetail.blog && blogDetail.blog.second_description"></div>
                        </b-card-text>
                      </b-card-body>
                    </b-card>
                  </div>
                </div>
              </div>
          </div>
          <div class="col-lg-4">
            <!-- <AdSearchBox class="custom-box"></AdSearchBox> -->
            <AdLatestPost v-bind:post_items="latest_post_items"></AdLatestPost>
            <!-- <AdCategoryBox v-bind:categories="category_items" class="custom-category-box"></AdCategoryBox> -->
            <!-- <AdTagBox v-bind:tag_items="tag_items"></AdTagBox> -->
          </div>
        </div>
      </div>
    </div>
</template>
<script>

import breadcrumb from "@core/components/breadcrumb/Breadcrumb";
import AdBlogPost from "@views/blog/blog-detail/components/ad-blog-post/AdBlogPost";
import AdSearchBox from "@views/blog/components/ad-search-box/AdSearchBox";
import AdCategoryBox from "@views/blog/components/ad-category-box/AdCategoryBox";
import AdTagBox from "@views/blog/components/ad-tag-box/AdTagBox";
import AdLatestPost from "@views/blog/blog-detail/components/ad-latest-post/AdLatestPost";
import { mapActions, mapGetters } from "vuex";

export default{
  name:"BlogDetail",
  components:{
    breadcrumb,
    AdBlogPost,
    AdSearchBox,
    AdCategoryBox,
    AdTagBox,
    AdLatestPost
  },
  data () {
    return{
      assetUrl: process.env.VUE_APP_ASSET_URL + '/',
      urlWeb: process.env.VUE_APP_URL + '/',
      bg: require("@assets/images/blog-details/blog_detail_02.jpg"),
      bg_title:"Designing For User Interfaces Icons As Visual Elements For Screen Design",
      custom_classes:'header_content_have_img',
      breadcrumbs: [
        {
          text: 'Home',
          href: '#'
        },
        {
          text: 'Blog',
          href: '#',
          active: true
        },
      ],
      post_infos:[
        {
          id:1,
          title:'A cleansing hot shower or bath',
          img:require('@assets/images/blog-details/blog_detail_01.jpg'),
          quote:'Viral dreamcatcher keytar typewriter, aest hetic offal umami. Aesthetic polaroid pug pitchfork post-ironic.',
          description:"Flyingfish Kafue pike cow shark California smoothtongue golden loach temperate ocean-bass gulper eel." +
              " Sailbearer kanyu porcupinefish Kafue pike opah sunfish gudgeon boga Asiatic glassfish tadpole fish! Alewife, " +
              "poacher airbreathing catfish; zebra tilapia northern pearleye naked-back knifefish pupfish dojo loach,",
          description_01:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidid unt ut ' +
              'labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut' +
              ' aliquip ex ea commodo consequat. Duis aute irure dolor in repreh enderit in voluptate velit esse cillum dolore' +
              ' eu fugiat nulla pariatur.'
        },
      ],
      category_items:[
        {id:1,item:'Business',number:'2',link:'#'},
        {id:2,item:'Consultant',number:'4',link:'#'},
        {id:3,item:'Education',number:'2',link:'#'},
        {id:4,item:'Help',number:'2',link:'#'},
        {id:5,item:'Technology',number:'2',link:'#'},
      ],
      tag_items:[
        {id:1,tag:'charity',link:'#'},
        {id:2,tag:'donate',link:'#'},
        {id:3,tag:'help',link:'#'},
        {id:4,tag:'ux',link:'#'},
        {id:5,tag:'water',link:'#'},
      ],
      latest_post_items:[
        {
          id:1,
          position:'Web Marketer',
          date:'August 6, 2022',
          img:require('@assets/images/blog-details/reply_profile_01.jpg'),
          alt:'reply_profile_01'
        },
        {
          id:2,
          position:'Web Designer',
          date:'August 6, 2022',
          img:require('@assets/images/blog-details/reply_profile.jpg'),
          alt:'reply_profile'
        },
        {
          id:3,
          position:'Web Development',
          date:'August 6, 2022',
          img:require('@assets/images/blog-details/blog_detail_01.jpg'),
          alt:'blog_detail_01'
        },
        {
          id:3,
          position:'Designing For User Interfaces Icons As Visual Elements For Screen Design',
          date:'August 6, 2022',
          img:require('@assets/images/blog-details/blog_detail_02.jpg'),
          alt:'blog_detail_02'
        },
        {
          id:4,
          position:'Beyond Tools How Building A Design System Can Improve How You Work',
          date:'August 6, 2022',
          img:require('@assets/images/blog-details/latest-post-04.jpg'),
          alt:'latest-post-04'
        },
        {
          id:5,
          position:'Make Honest Design Work As For Digital Business.',
          date:'August 6, 2022',
          img:require('@assets/images/blog-details/lastest-post-01.jpg'),
          alt:'lastest-post-01'
        },
        {
          id:6,
          position:'Beyond Tools How Building A Design System Can Improve How You Work',
          date:'August 6, 2022',
          img:require('@assets/images/blog-details/latest-post-02.jpg'),
          alt:'latest-post-02'
        },
        {
          id:7,
          position:'Adobe XD Contest: Create An App Prototype With Your City’s Best-Kept Secrets',
          date:'August 6, 2022',
          img:require('@assets/images/blog-details/blog_detail_03.jpg'),
          alt:'blog_detail_03'
        }
      ],

    }
  },
  methods: {
    ...mapActions(["fetchBlogDetail"]),
  },
  computed: {
    ...mapGetters(["blogDetail"]),
  },
  mounted() {
    this.fetchBlogDetail({
        filter: {
          locale: this.$router.currentRoute.params.locale
            ? this.$router.currentRoute.params.locale
            : 'en',
        },
        slug: this.$router.currentRoute.query.text
          ? this.$router.currentRoute.query.text
          : null,
        // uuid: this.$router.currentRoute.query._post
        //   ? this.$router.currentRoute.query._post
        //   : null,
    });
  },
  watch: {
        $route(to, from) {
        if (from.params.locale && to.params.locale !== from.params.locale) {
          this.fetchBlogDetail({
            filter: {
              locale: this.$router.currentRoute.params.locale
                ? this.$router.currentRoute.params.locale
                : 'en',
            },
            // slug: this.$router.currentRoute.query
            //   ? this.$router.currentRoute.query.text
            //   : null,
            uuid: this.$router.currentRoute.query
              ? this.$router.currentRoute.query._post
              : null,
          });
        }
      },
  },
  // seo
    metaInfo () {
      let locale = localStorage.getItem('_locale')
      locale = locale ? JSON.parse(locale) : null
      return {
            // title: 'Linda Ojo\'s Personal website and Blog',
        title: (this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.title
        ? this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.title
        : 'Blog Detail'),
        titleTemplate:
                '%s | ' +
                (this.$root.general.settings.app_name
                ? this.$root.general.settings.app_name
                : 'Aditi Wesbite'),
        htmlAttrs: {
        lang: locale && locale.title ? locale.title : 'en'
        },
        meta: [
        {
            vmid: 'description',
            name: 'description',
            content: (this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.short_description)
            ? this.blogDetail.blog.short_description
            : (this.$root.general.settings.description
                ? this.$root.general.settings.description
                : '')
        },
        {
            vmid: 'keyword',
            name: 'keyword',
            content: (this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.keyword)
            ? this.blogDetail.blog.keyword
            : ''
        },
        //  Facebook, Whatsapp 
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: (this.$root.general.settings.app_name) },
        { property: 'og:title', content: (this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.title) },
        { property: 'og:description', content: (this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.short_description) },
        { property: 'og:image', content: (this.blogDetail && this.blogDetail.blog && this.assetUrl+this.blogDetail.blog.image)},
        { property: 'og:image:alt', content: ''},
        { property: 'og:url', content: `${this.urlWeb}en/solution-detail?text=${this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.slug}`},
        //  linkIn
        { property: 'linkedin:title', content: (this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.title) },
        { property: 'linkedin:description', content: (this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.short_description) },
        { property: 'linkedin:image', content: (this.blogDetail && this.blogDetail.blog && this.assetUrl+this.blogDetail.blog.image)},
        { property: 'linkedin:url', content: `${this.urlWeb}en/solution-detail?text=${this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.slug}` },
        //  Twitter 
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:title', content: (this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.title) },
        { property: 'twitter:description', content: (this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.short_description) },
        { property: 'twitter:image', content: (this.blogDetail && this.blogDetail.blog && this.assetUrl+this.blogDetail.blog.image)},
        { property: 'twitter:url', content: `${this.urlWeb}en/solution-detail?text=${this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.slug}` },

          // telegram
        { property: 'telegram:title', content: (this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.title ) },
        { property: 'telegram:description', content: (this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.short_description) },
        { property: 'telegram:image', content: (this.blogDetail && this.blogDetail.blog && this.assetUrl+this.blogDetail.blog.image)},
        { property: 'telegram:url', content: `${this.urlWeb}en/solution-detail?text=${this.blogDetail && this.blogDetail.blog && this.blogDetail.blog.slug}`},
        ]
      }


    },

}
</script>
<style lang="scss">
@import "@assets/scss/pages/blog/blog-detail/blog-detail.scss";
</style>