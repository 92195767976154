import { ApiService } from "@core/services";
import { api } from "@core/config/api";

export class FooterService extends ApiService {
  endpoint = api.endpoint;

  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }
  async fetchFooter(data) {
    return this.post(this.endpoint.sent_client_mail, data, {
      headers: {
        "Subject": "multipart/form-data",
      },
      transformRequest: [
        function (data, headers) {
          headers["Subject"] = "multipart/form-data";
          return data;
        },
      ],
    });
  }
}
