<template>
  <div class="contact">
    <breadcrumb 
      v-bind:backgroundImg="contact && contact.breadcrumb && assetUrl + contact.breadcrumb.image ? assetUrl + contact.breadcrumb.image: bg_img" 
      v-bind:title="contact && contact.breadcrumb && contact.breadcrumb.title ? contact.breadcrumb.title : title ">
    </breadcrumb>
    <section class="ad-contact-card sy">
      <div class="container">
        <div class="d-flex ">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-6 pt-4">
              <div class="contact-box">
                <div class="box-wrap">
                  <a :href="'mailto:'+($root && $root.settings && $root.settings.email)">
                    <div class="icon-wrap">
                      <b-icon-envelope></b-icon-envelope>
                    </div>
                  </a>
                  <div class="title-head">
                    <h4 class="title">Email Address </h4>
                    <span class="sub-title">Send mail anytime</span>
                  </div>
                </div>
                <div class="contact-wrap">
                  <p>{{ $root && $root.settings && $root.settings.email }}</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-6 pt-4">
              <div class="contact-box">
                <div class="box-wrap">
                  <a :href="'tel:'+($root && $root.settings && $root.settings.phone)">
                    <div class="icon-wrap">
                      <b-icon-telephone></b-icon-telephone>
                    </div>
                  </a>
                  <div class="title-head">
                    <h4 class="title">Phone Number</h4>
                    <span class="sub-title">Send mail anytime</span>
                  </div>
                </div>
                <div class="contact-wrap">
                  <p>{{ $root && $root.settings && $root.settings.phone }}</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-6 pt-4">
              <div class="contact-box">
                <div class="box-wrap">
                  <div class="icon-wrap">
                    <b-icon-geo-alt-fill></b-icon-geo-alt-fill> 
                  </div>
                  <div class="title-head">
                    <h4 class="title">Office Address</h4>
                    <span class="sub-title">Send mail anytime</span>
                  </div>
                </div>
                <div class="contact-wrap">
                  <p>{{ $root && $root.settings && $root.settings.address }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="map">
      <b-container>
        <iframe
          class="gmap_iframe mt-4"
          src="https://maps.google.com/maps?hl=en&amp;q=Camsolution Technology&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>
      </b-container>
    </div>
    
    <div class="form-section">
      <b-container>
        <div class="main-title">
          <h2 class="title">{{ main_title }}</h2>
          <div class="sub_title_wrap">
            <h1 class="sub_title">{{ sub_title }}</h1>
          </div>
        </div>
        <form id="form" novalidate autocomplete="off" autofocus class="needs-validation mt-5" 
        enctype="multipart/form-data" @submit.prevent="onSubmit($event)">
          <div class="row">
            <div class="col-md-6 margin_col">
              <label for="InputFullName" class="form-label">Full name</label>
              <input v-model="formRequest.full_name"
                :class="{ 'is-invalid mb-3': $v.formRequest.full_name.$error}"
                name="full_name" type="text" class="form-control" id="InputFullName"
                :placeholder="$root && $root.translates && $root.translates.full_name ? $root.translates.full_name :'full name'" 
              />
              <div v-if="!$v.formRequest.full_name.required" class="invalid-feedback">
                {{
                  $root.translates && $root.translates["full_name_validation"] 
                  ? $root.translates["full_name_validation"]
                  : "Please enter full name"
                }}.
              </div>
            </div>
            <div class="col-md-6 margin_col">
              <label for="InputEmail" class="form-label">Email Address </label>
              <input v-model="formRequest.email_address"
                :class="{
                  'is-invalid mb-3': $v.formRequest.email_address.$error,
                }"
                type="email" name="email_address" class="form-control" id="InputEmail"
                :placeholder="$root && $root.translates && $root.translates.email_address ? $root.translates.email_address:'Email address'" />
                <div v-if="!$v.formRequest.email_address.required" class="invalid-feedback" >
                  {{
                    $root.translates && $root.translates["email_address_validation"]
                    ? $root.itranslates["email_address_validation"]
                    : "Please enter email address" 
                  }}.
                </div> 
                <div v-else-if="!$v.formRequest.email_address.email_address" class="invalid-feedback" >
                  {{
                    $root.translates && $root.translates["email_address_invalid_validation"]
                      ? $root.translates["email_address_invalid_validation"]
                      : "Please enter invalid email address"
                  }}.
                </div>
            </div>
            <div class="col-md-6 margin_col">
              <label for="InputPhoneNumber" class="form-label">Phone Number </label>
              <input v-model="formRequest.phone_number"
                :class="{ 'is-invalid mb-3': $v.formRequest.phone_number.$error}"
                name="phone_number" type="text" class="form-control" id="InputPhoneNumber"
                :placeholder="$root && $root.translates && $root.translates.phone_number ?  $root.translates.phone_number : 'phone number'" 
              />
              <div v-if="!$v.formRequest.phone_number.required" class="invalid-feedback">
                {{
                  $root.translates && $root.translates["phone_number_validation"] 
                  ? $root.translates["phone_number_validation"]
                  : "Please enter phone number"
                }}.
              </div>
            </div>
            <div class="col-md-6 margin_col">
              <label for="InputSubject" class="form-label">Subject </label>
              <input v-model="formRequest.subject"
                :class="{ 'is-invalid mb-3': $v.formRequest.subject.$error}"
                name="subject" type="text" class="form-control" id="InputSubject"
                :placeholder="$root && $root.translates && $root.translates.subject ? $root.translates.subject:'subject' " 
              />
              <div v-if="!$v.formRequest.subject.required" class="invalid-feedback">
                {{
                  $root.translates && $root.translates["subject_validation"] 
                  ? $root.translates["subject_validation"]
                  : "Please enter subject"
                }}.
              </div>
            </div>
            <div class="col-md-12 margin_col">
              <label for="InputMessage" class="form-label">Message </label>
              <textarea v-model="formRequest.message"
                :class="{
                  'is-invalid mb-3': $v.formRequest.message.$error,
                }"
                class="form-control" id="InputMessage" rows="3"
                :placeholder="$root && $root.translates && $root.translates.message ? $root.translates.message : 'message'">
              </textarea>
              <div v-if="!$v.formRequest.message.required" class="invalid-feedback">
                {{  $root.translates && $root.translates["message_validation"]
                    ? $root.translates["message_validation"]
                    : "Please enter your message" }}.
              </div>
            </div>
            <vue-recaptcha 
              @verify="markRecaptchaAsVerified" 
              sitekey="6LdhSdsiAAAAAAMLryuiLsUdvUBp4Zmq3ZiBP2C6">
            </vue-recaptcha>
            <div class="invalid-recaptcha">
              {{ pleaseTickRecaptchaMessage }}
            </div>
          </div>
          <div class="btn-wrap">
            <button type="submit" class="btn text-uppercase btn-submit">
              send message <b-icon-arrow-right class="arrow-right"></b-icon-arrow-right>
            </button>
          </div>
        </form>
      </b-container>
    </div>
  </div>
</template>

<script>
  import { VueRecaptcha } from 'vue-recaptcha';
  import breadcrumb from '@core/components/breadcrumb/Breadcrumb';
  import { validationMixin } from "vuelidate";
  import { required, email } from "vuelidate/lib/validators";
  import { mapActions, mapGetters } from 'vuex';
  import api from "@/api";
  export default {
    name: 'Contact',
    components: {
      breadcrumb,
      VueRecaptcha,
    },
    mixins: [validationMixin],
    setup() {
      function toastSuccess(message) {
        this.$toast.success(message);
      }
      function toastWarning(message) {
        this.$toast.warning(message);
      }
      function toastInfo(message) {
        this.$toast.info(message);
      }
      function toastError(message) {
        this.$toast.error(message);
      }
      function toastDefault(message) {
        this.$toast(message);
      }
      return {
        toastDefault,
        toastSuccess,
        toastInfo,
        toastWarning,
        toastError,
      };
    },
    data() {
      return {
        assetUrl: process.env.VUE_APP_ASSET_URL + '/',
        formRequest: {
          full_name: null,
          email_address: null,
          phone_number: null,
          subject: null,
          message: null,
          recaptchaVerified: false,
        },
        pleaseTickRecaptchaMessage:'',
        bg_img: require('@assets/images/blog-details/reply_profile.jpg'),
        title: 'Contact',
        main_title: 'SEND US MESSAGE',
        sub_title: 'Don’t Hesited To Contact Us Say Hello Or Message',
      };
    },
    methods: {
      ...mapActions(['fetchContactUs']),
      markRecaptchaAsVerified(response) {
        this.formRequest.recaptchaVerified = true;
        this.pleaseTickRecaptchaMessage = '';
      },
      onSubmit() {
        if (!this.formRequest.recaptchaVerified) {
          this.pleaseTickRecaptchaMessage = 'please tick recaptcha';
          return true; 
        }
        this.$root.loading = true;
        this.$root._loading();
        // stop here if form is invalid
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$root.loading = false;
          this.$root._loading();
          this.toastError("Please enter all information that are required.");
          return false;
        }
        api.contact
          .fetchContact(this.getFormData(this.formRequest))
          .then((response) => {
            this.toastSuccess(response.data.message);
            this.$root.loading = false;
            this.$root._loading();
            this.formRequest= {
              full_name: null,
              email_address: null,
              recaptchaVerified: false,
            };
            this.$v.$reset();
            grecaptcha.reset();
          })
          .catch((error) => {
            const hasError = error.response.data.error;
            if (hasError && hasError.validate) {
              const errorValidate = hasError.validate;
              this.onValidateError(errorValidate);
              this.loading = false;
              if (!errorValidate) {
                this.formRequest = {
                  email_address: null,
                  message: null,
                };
                this.toastSuccess(
                  "Thank You for contact us, We will contact back soon."
                );
              }
            } else {
              this.toastError("You cannot sent request successfully!");
            }
          })
          .finally(() => {
            this.$root.loading = false;
            this.$root._loading();
          });
      },
      getFormData(data) {
        const formData = new FormData();
        formData.append("full_name", data.full_name);
        formData.append("email_address", data.email_address);
        formData.append("phone_number", data.phone_number);
        formData.append("subject", data.subject);
        formData.append("message", data.message);
        return formData;
      },
      
      onValidateError(errorValidate) {
        if (errorValidate.full_name) {
          this.toastError(errorValidate.full_name[0]);
        }
        if (errorValidate.phone_number) {
          this.toastError(errorValidate.phone_number[0]);
        }
        if (errorValidate.email_address) {
          this.toastError(errorValidate.email_address[0]);
        }
        if (errorValidate.subject) {
          this.toastError(errorValidate.subject[0]);
        }
        if (errorValidate.message) {
          this.toastError(errorValidate.message[0]);
        }
      },
    },
    computed: {
      ...mapGetters(['contact']),
    },
    validations: {
      formRequest: {
        full_name: { required },
        email_address: { required, email },
        phone_number: { required },
        subject: { required},
        message: { required },
      },
    },
    mounted() {
      this.fetchContactUs({
        filter: {
          locale: this.$router.currentRoute.params.locale
            ? this.$router.currentRoute.params.locale
            : 'en',
        },
      });
    },

    // seo
    metaInfo () {
      let locale = localStorage.getItem('_locale')
      locale = locale ? JSON.parse(locale) : null
      return {
        title: (this.contact && this.contact.breadcrumb && this.contact.breadcrumb.title
        ? this.contact.breadcrumb.title
        : 'contact'),
        titleTemplate:
                '%s | ' +
                (this.$root.general.settings.app_name
                ? this.$root.general.settings.app_name
                : 'Aditi Wesbite'),
        htmlAttrs: {
        lang: locale && locale.title ? locale.title : 'en'
        },
        meta: [
        {
            vmid: 'description',
            name: 'description',
            content: (this.contact && this.contact.breadcrumb && this.contact.breadcrumb.description)
            ? this.contact.breadcrumb.description
            : (this.$root.general.settings.description
                ? this.$root.general.settings.description
                : '')
        },
        {
            vmid: 'keyword',
            name: 'keyword',
            content: (this.contact && this.contact.breadcrumb && this.contact.breadcrumb.keyword)
            ? this.contact.breadcrumb.keyword
            : ''
        }
        ]
      }
    },

  };
</script>

<style lang="scss">
  @import '@assets/scss/pages/contact/contact.scss';
  @import "@assets/scss/pages/contact/components/contact-card.scss";
</style>
