var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog"},[_c('breadcrumb',{attrs:{"backgroundImg":_vm.blog && _vm.blog.breadcrumb && _vm.assetUrl + _vm.blog.breadcrumb.image
        ? _vm.assetUrl + _vm.blog.breadcrumb.image
        : _vm.background,"title":_vm.blog && _vm.blog.breadcrumb && _vm.blog.breadcrumb.title
        ? _vm.blog.breadcrumb.title
        : 'Solution'}}),_c('div',{staticClass:"blog-post"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"d-none d-md-block"},[_c('div',{staticClass:"cate-box"},[_c('div',{staticClass:"title-wrap"},[_c('div',{staticClass:"icon"},[_c('b-icon',{attrs:{"icon":"dash-lg"}}),_c('b-icon',{attrs:{"icon":"dot"}})],1),_c('h2',{staticClass:"title"},[_vm._v("Categories")])])])]),_c('div',[_c('b-tabs',{attrs:{"pills":"","card":"","vertical":"","end":""}},_vm._l((_vm.blog.blogCategory),function(item,index){return _c('div',{key:'item' + index},[_c('b-tab',{attrs:{"title":item.title,"active":""}},[_c('div',{staticClass:"card-post"},_vm._l((item.blogs),function(blog){return _c('b-card',{key:blog.id,staticClass:"mb-4"},[_c('b-link',{attrs:{"to":{
                      name: 'SolutionDetail',
                      query: {
                        text: blog.slug,
                      },
                    }}},[_c('b-card-img',{staticClass:"img-fluid card-img-top",attrs:{"src":_vm.assetUrl + blog.image,"alt":blog.title}})],1),_c('b-card-body',{staticClass:"body-list"},[_c('b-card-title',[_c('b-link',{attrs:{"to":{
                            name: 'SolutionDetail',
                            query: {
                              text: blog.slug,
                            },
                          }}},[_vm._v(" "+_vm._s(blog.title)+" ")])],1),_c('div',{staticClass:"post-meta"},[_c('div',{staticClass:"post-date"},[_c('b-link',[_c('b-icon',{staticClass:"calendar-icon",attrs:{"icon":"calendar2-week"}}),_c('span',[_vm._v(_vm._s(blog.date))])],1)],1)]),_c('b-card-text',{staticClass:"four-line"},[_vm._v(" "+_vm._s(blog.short_description)+" ")])],1),_c('b-card-footer',[_c('div',{staticClass:"profile d-flex justify-content-between"},[_c('div',{staticClass:"blog-author-meta"},[_c('div',{staticClass:"author-thumbnail"},[_c('b-img',{staticClass:"img-fluid",attrs:{"src":_vm.assetUrl + blog.profile,"alt":blog.writer}})],1),_c('div',{staticClass:"blog-author-name"},[_c('h5',[_vm._v(_vm._s(blog.writer))])])]),_c('div',{staticClass:"post-read-more"},[_c('b-link',{attrs:{"to":{
                            name: 'SolutionDetail',
                            query: {
                              text: blog.slug,
                            },
                          }}},[_vm._v("Read More"),_c('b-icon',{staticClass:"arrow-right",attrs:{"icon":"arrow-right"}})],1)],1)])])],1)}),1)])],1)}),0)],1)])]),_c('div',{staticClass:"paginations d-none"},[_c('b-container',[_c('b-pagination',{attrs:{"pills":"","total-rows":_vm.rows,"hide-ellipsis":true,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }