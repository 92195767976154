var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sticky-top",attrs:{"id":"header"}},[_c('div',{staticClass:"top-bar"},[_c('div',{staticClass:"overlay"}),_c('div',{staticClass:"container top-head"},[_c('div',{staticClass:"info d-flex align-items-center"},[_c('p',[_c('a',{attrs:{"href":"mailto:someone@example.com"}},[_c('b-icon-envelope'),_vm._v("info@aditiasia.com")],1)]),_c('p',[_c('a',{attrs:{"href":"tel:123-456-7890"}},[_c('b-icon-telephone'),_vm._v("123-456-7890")],1)])]),_c('div',{staticClass:"social"},[_c('b-navbar-nav',{staticClass:"text nav flex-row justify-content-center"},[_c('b-nav-item',{attrs:{"to":{name:'#'}}},[_c('b-icon-facebook')],1),_c('b-nav-item',{attrs:{"to":{name:'#'}}},[_c('b-icon-instagram')],1),_c('b-nav-item',{attrs:{"to":{name:'#'}}},[_c('b-icon-twitter')],1),_c('b-nav-item',{attrs:{"to":{name:'#'}}},[_c('b-icon-linkedin')],1)],1)],1)])]),_c('section',{staticClass:"header-group"},[_c('div',{staticClass:"container"},[_c('b-navbar',{attrs:{"toggleable":"lg"}},[_c('div',{staticClass:"logo"},[_c('b-link',{attrs:{"to":{ name: 'Home' }}},[_c('b-img',{attrs:{"src":_vm.$root.settings.logo 
              ? _vm.$root.settings.logo
              : require('@/assets/images/logo/main-logo.png'),"fluid":"","alt":"Camsolution Logo"}})],1)],1),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}},[_c('b-icon-list')],1),_c('b-collapse',{staticClass:"justify-content-center",attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"text nav"},[_c('b-nav-item',{attrs:{"to":{ name: 'Home' }}},[_vm._v(_vm._s(_vm.$root.translates && _vm.$root.translates.menu_home ? _vm.$root.translates.menu_home : "Home"))]),_c('b-nav-item',{attrs:{"to":{ name: 'Service' }}},[_vm._v(_vm._s(_vm.$root.translates && _vm.$root.translates.service ? _vm.$root.translates.service : "Service"))]),_c('b-nav-item',{attrs:{"to":{ name: 'About' }}},[_vm._v(_vm._s(_vm.$root.translates && _vm.$root.translates.about ? _vm.$root.translates.about : "About"))]),_c('b-nav-item',{attrs:{"to":{ name: 'team' }}},[_vm._v(_vm._s(_vm.$root.translates && _vm.$root.translates.teams ? _vm.$root.translates.teams : "Teams"))]),_c('b-nav-item',{attrs:{"to":{ name: 'Blog' }}},[_vm._v(_vm._s(_vm.$root.translates && _vm.$root.translates.menu_blog ? _vm.$root.translates.menu_blog : "Blog"))]),_c('b-nav-item',{attrs:{"to":{ name: 'Portfolio' }}},[_vm._v(_vm._s(_vm.$root.translates && _vm.$root.translates.portfolio ? _vm.$root.translates.portfolio : "Portfolio"))]),_c('b-nav-item',{staticClass:"btn-consultant d-lg-none",attrs:{"to":{ name: 'Consultant' }}},[_vm._v(_vm._s(_vm.$root.translates && _vm.$root.translates.consultancy ? _vm.$root.translates.consultancy : "Consultancy")),_c('b-icon-arrow-right-short')],1),_c('b-nav-item-dropdown',{staticClass:"lang d-lg-none",attrs:{"text":_vm.$root.general &&
              _vm.$root.general.locales &&
              _vm.$root.general.locales.currentActive
                ? _vm.$root.general.locales.currentActive.title
                : 'EN'}},_vm._l((_vm.$root.general.locales.all),function(locale){return _c('b-dropdown-item',{key:locale.title,on:{"click":function($event){return _vm.onSwitch(locale)}}},[_vm._v(_vm._s(locale.language))])}),1)],1)],1),_c('b-navbar-nav',{staticClass:"right-menu nav"},[_c('b-nav-item',{staticClass:"btn-consultant",attrs:{"to":{ name: 'Consultant' }}},[_vm._v(_vm._s(_vm.$root.translates && _vm.$root.translates.consultancy ? _vm.$root.translates.consultancy : "Consultancy")+" "),_c('b-icon-arrow-right-short')],1),_c('b-nav-item-dropdown',{staticClass:"lang",attrs:{"text":_vm.$root.general &&
              _vm.$root.general.locales &&
              _vm.$root.general.locales.currentActive
                ? _vm.$root.general.locales.currentActive.title
                : 'EN'}},_vm._l((_vm.$root.general.locales.all),function(locale){return _c('b-dropdown-item',{key:locale.title,on:{"click":function($event){return _vm.onSwitch(locale)}}},[_vm._v(_vm._s(locale.language))])}),1)],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }