<template>
  <div class="portfolio-detail">
    <Breadcrumb 
    :title="portfolioDetail && portfolioDetail.portfolio && portfolioDetail.portfolio.title ? portfolioDetail.portfolio.title: breadcrumbTitle" 
    :backgroundImg="portfolio && portfolio.breadcrumb && assetUrl + portfolio.breadcrumb.image ? assetUrl + portfolio.breadcrumb.image : breadcrubBackground">
    </Breadcrumb>
    <section class="portflio">
      <div class="container">
        <CoolLightBox v-if="portfolioDetail && portfolioDetail.portfolio && portfolioDetail.portfolio.gallery"
          :items="itemsLightBox(portfolioDetail.portfolio.gallery)"
          :index="index"
          @close="index = null"
        >
        </CoolLightBox>
        <div class="images-wrapper">
          <div class="row" v-if="portfolioDetail && portfolioDetail.portfolio && portfolioDetail.portfolio.gallery">
            <div 
              class="col-6"
              v-for="(images, imageIndex) in portfolioDetail.portfolio.gallery"
              :key="imageIndex"
              @click="index = imageIndex"
            >
              <div class="image portflio-img" v-if="imageIndex < perPage">
                <img :src=" assetUrl + images.image" :alt="portfolioDetail && portfolioDetail.portfolio && portfolioDetail.portfolio.title" class="img-fluid" />
              </div>
            </div>
          </div> 
          <div class="all-photo"  @click="index = 0">
            <div class="btn-group">
              <button href="#" class="btn btn-transparent">
                <span class="icon icon-category">
                  <b-icon-grid-fill></b-icon-grid-fill> </span>All photo
              </button>
            </div>
          </div>
        </div>
        <div class="portflio-content">
          <div class="row">
            <div class="col-xs-12 col-md-6 col-xl-8">
              <div class="content-left pe-2" 
              v-html="portfolioDetail && portfolioDetail.portfolio && portfolioDetail.portfolio.descript ">
                
              </div>
            </div>
            <div class="col-xs-12 col-md-6 col-xl-4">
              <div class="content-right">
                <div
                  class="project-detail" 
                >
                  <div class="title">
                    <h1
                      :style="{
                        'background-image': 'url(' + backgroundImage + ')',
                      }"
                    >
                      Porject Information
                    </h1>
                    
                  </div>
                  <ul class="desc">
                    <li>
                      <span class="label">Clients</span>
                      <span class="value">
                        {{ portfolioDetail && portfolioDetail.portfolio && portfolioDetail.portfolio.client }}</span>
                    </li>
                    <li>
                      <span class="label">Project</span>
                      <span class="value">
                        {{ portfolioDetail && portfolioDetail.portfolio && portfolioDetail.portfolio.title }}</span>
                    </li>
                    <li>
                      <span class="label">Service</span>
                      <span class="value">
                        {{ portfolioDetail && portfolioDetail.portfolio && portfolioDetail.portfolio.service.title }}</span>
                    </li>
                    <li>
                      <span class="label">Category</span>
                      <span class="value">
                        {{ portfolioDetail && portfolioDetail.portfolio
                         && portfolioDetail.portfolio.category.title }}</span>
                    </li>
                    <!-- <li>
                      <span class="label">Date</span>
                      <span class="value">
                        {{ portfolioDetail && portfolioDetail.portfolio && portfolioDetail.portfolio.date }}</span>
                    </li> -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Testimonail>
    </Testimonail>
  </div>
</template>
<script>
  import Testimonail from '@/components/testimonail/Testimonail.vue';
  import Breadcrumb from '@/core/components/breadcrumb/Breadcrumb.vue';

  import CoolLightBox from 'vue-cool-lightbox'
  import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
 
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'Portfolio-detail',
    components: {
      Breadcrumb,
      Testimonail,
      CoolLightBox,
    },
    data() {
      return {
        assetUrl: process.env.VUE_APP_ASSET_URL + '/',
        breadcrumbTitle: 'Portfolio Detail',
        breadcrubBackground: require('@/assets/images/breadcrumb/Artboard4.jpg'),
        backgroundImage: require('@assets/images/portfolio-detail/case-head.png'),
        index: null,
        perPage: 4,
      };

    },
    
    methods: {
      ...mapActions(['fetchPortfolioDetail','fetchPortfolio']),
      itemsLightBox:function(items){
        let images=[];
        items.forEach(function(item,i){
          images[i]=process.env.VUE_APP_ASSET_URL + '/'+item.image;
          
        })
        return images;
        
      }
    },

    computed: {
      ...mapGetters(['portfolioDetail','portfolio']),
    },

    mounted() {
      this.fetchPortfolio({
        filter: {
          locale: this.$router.currentRoute.params.locale
            ? this.$router.currentRoute.params.locale
            : 'en',
        },
      });
      this.fetchPortfolioDetail({
        filter: {
          locale: this.$router.currentRoute.params.locale
            ? this.$router.currentRoute.params.locale
            : 'en',
        },
        uuid: this.$router.currentRoute.query._post
          ? this.$router.currentRoute.query._post
          : null,
          
      });
    },

    watch: {
      $route(to, from) {
        if (from.params.locale && to.params.locale !== from.params.locale) {
          this.fetchPortfolioDetail({
            filter: {
              locale: this.$router.currentRoute.params.locale
                ? this.$router.currentRoute.params.locale
                : 'en',
            },
            uuid: this.$router.currentRoute.query
              ? this.$router.currentRoute.query._post
              : null,
          });
        }
      },
    },

  };
</script>
<style lang="scss">
  @import '@assets/scss/pages/portfolio-detail.scss';
</style>
