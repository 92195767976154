<template>
  <div class="service-detail sy">
    <section class="form-section">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-5 col-md-4 flex-xxl-row-reverse">
            <section class="section-contact">
              <b-navbar-nav class="text nav">
                <b-nav-item
                  v-for="(service, index) in home && home.serviceList"
                  :key="'service' + index"
                  :to="{
                    name: 'ServiceDetail',
                    query: {
                      _post: service.uuid,
                      text: service.slug,
                    },
                  }"
                >
                  {{ service.title }}
                </b-nav-item>
              </b-navbar-nav>
              <div class="form-contact">
                <h2 class="title">{{$root.translates && $root.translates.contact_us ? $root.translates.contact_us : "Contact Us" }}</h2>
                <input
                  type="text"
                  name="name"
                  class="email"
                  aria-required="true"
                  aria-invalid="false"
                  placeholder="Full Name"
                />
                <input
                  type="number"
                  name="email-707"
                  class="email"
                  aria-required="true"
                  aria-invalid="false"
                  placeholder="Phone"
                />
                <input
                  type="email"
                  name="email-707"
                  class="email"
                  aria-required="true"
                  aria-invalid="false"
                  placeholder="Email"
                />
                <textarea
                  name="textarea-339"
                  class="textarea"
                  aria-invalid="false"
                  placeholder="Write Message"
                ></textarea>
                <Button link="#" class="bg-color" title="Send Message"></Button>
              </div>
            </section>
          </div>
          <div class="col-sm-7 col-md-8">
            <section class="section-service">
              <div class="form-service">
                <div class="image">
                  <img
                    :src="
                      serviceDetail &&
                      serviceDetail.service &&
                      assetUrl + serviceDetail.service.thumbnail
                    "
                    class="img-fluid"
                    :alt="
                      serviceDetail &&
                      serviceDetail.service &&
                      serviceDetail.service.title
                    "
                  />
                </div>
                <div class="content-center">
                  <div class="box-content">
                    <div class="title">
                      <h1>
                        {{
                          serviceDetail &&
                          serviceDetail.service &&
                          serviceDetail.service.title
                        }}
                      </h1>
                    </div>
                    <div class="descript">
                      <p>
                        {{
                          serviceDetail &&
                          serviceDetail.service &&
                          serviceDetail.service.shortDescript
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="thumbnail">
                  <img
                    :src="
                      serviceDetail &&
                      serviceDetail.service &&
                      assetUrl + serviceDetail.service.image
                    "
                    :alt="serviceDetail &&
                      serviceDetail.service &&
                      serviceDetail.service.title"
                    class="img-fluid"
                  />
                </div>
                <div
                  class="text-detail"
                  v-html="
                    serviceDetail &&
                    serviceDetail.service &&
                    serviceDetail.service.Descript
                  "
                ></div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script src="https://www.google.com/recaptcha/api.js" async defer></script>
<script>
import Button from "@/core/components/button/Button";
import { mapActions, mapGetters } from "vuex";
import { VueRecaptcha } from 'vue-recaptcha';
export default {
  name: "ServiceDetail",
  components: {
    Button,
    VueRecaptcha
  },
  data() {
    return {
      assetUrl: process.env.VUE_APP_ASSET_URL + "/",
    };
  },
  methods: {
    ...mapActions(["fetchHome"]),
    ...mapActions(["fetchServiceDetail"]),
  },
  computed: {
    ...mapGetters(["home"]),
    ...mapGetters(["serviceDetail"]),
  },
  mounted() {
    this.fetchServiceDetail({
      filter: {
        locale: this.$router.currentRoute.params.locale
          ? this.$router.currentRoute.params.locale
          : "en",
      },
      uuid: this.$router.currentRoute.query._post
        ? this.$router.currentRoute.query._post
        : null,
    });
    this.fetchHome({
      filter: {
        locale: this.$router.currentRoute.params.locale
          ? this.$router.currentRoute.params.locale
          : "en",
      },
    });
  },
  watch: {
    $route(to, from) {
      if (from.params.locale && to.params.locale !== from.params.locale) {
        this.fetchServiceDetail({
          filter: {
            locale: this.$router.currentRoute.params.locale
              ? this.$router.currentRoute.params.locale
              : "en",
          },
          uuid: this.$router.currentRoute.query
            ? this.$router.currentRoute.query._post
            : null,
        });
        this.fetchHome({
          filter: {
            locale: this.$router.currentRoute.params.locale
                ? this.$router.currentRoute.params.locale
                : "en",
          },
        });
      } else if (from.query._post !== to.query._post) {
        this.fetchServiceDetail({
          filter: {
            locale: this.$router.currentRoute.params.locale
              ? this.$router.currentRoute.params.locale
              : "en",
          },
          uuid: to.query && to.query._post ? to.query._post : null,
        });
        this.fetchHome({
          filter: {
            locale: this.$router.currentRoute.params.locale
              ? this.$router.currentRoute.params.locale
              : "en",
          },
          uuid: to.query && to.query._post ? to.query._post : null,
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@assets/scss/pages/service-detail/service-detail.scss";
</style>
