 import { TranslationService } from "./translation";
 import { SettingService } from "./setting";
 import { HomeService } from "./home";
 import { ConditionService } from "./term-condition";
 import { ItemService } from "./service";
 import { AboutService } from "./about";
 import { CareerService } from "./career";
 import { PortfolioService } from "./portfolio";
 import { PrivacyService } from "./privacy";
 import { TeamService } from "./team";
 import { FaqService } from "./faq";
 import { ContactService } from "./consaltan";
 import { ComponentService } from "./component";
 import { FooterService } from "./footer";
 import { BlogService } from "./blog";

const api = {
  translation: new TranslationService(),
   setting: new SettingService(),
   home: new HomeService(),
   condition: new ConditionService(),
   service: new ItemService(),
   about: new AboutService(),
   career: new CareerService(),
   portfolio: new PortfolioService(),
   privacy: new PrivacyService(),
   team: new TeamService(),
   faq: new FaqService(),
   contact: new ContactService(),
   footer: new FooterService(),
   blog: new BlogService(),

  component: new ComponentService(),
  //  counter: new CounterService(),
};
export default api;
