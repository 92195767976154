import api from "@/api";
const state = {
    contact: {},
}
const getters = {
    contact: (state) => state.contact,
};

const actions = {
    async fetchContactUs({commit} , params){
        await api.contact.fetchContactUs(params).then((response) => {
            commit("setContactUs" , response.data);
        })
    }
}
const mutations = {
    setContactUs: (state, contact) => (state.contact = contact),
};

export default{
    state,
    getters,
    actions,
    mutations,
};