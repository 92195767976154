<template>
  <div class="blog">
    <breadcrumb
      v-bind:backgroundImg="
        blog && blog.breadcrumb && assetUrl + blog.breadcrumb.image
          ? assetUrl + blog.breadcrumb.image
          : background
      "
      v-bind:title="
        blog && blog.breadcrumb && blog.breadcrumb.title
          ? blog.breadcrumb.title
          : 'Solution'
      "
    >
    </breadcrumb>
    <div class="blog-post">
      <div class="container">
        <div class="d-none d-md-block">
          <div class="cate-box">
            <div class="title-wrap">
              <div class="icon">
                <b-icon icon="dash-lg"></b-icon>
                <b-icon icon="dot"></b-icon>
              </div>
              <h2 class="title">Categories</h2>
            </div>
          </div>
        </div>
        <div>
          <!-- <b-card no-body> -->
          <b-tabs pills card vertical end>
            <div
              v-for="(item, index) in blog.blogCategory"
              :key="'item' + index"
            >
              <b-tab :title="item.title" active>
                <div class="card-post">
                  <b-card
                    v-for="blog in item.blogs"
                    :key="blog.id"
                    class="mb-4"
                  >
                    <b-link
                      :to="{
                        name: 'SolutionDetail',
                        query: {
                          text: blog.slug,
                        },
                      }"
                    >
                      <!-- <b-link
                          :to="{
                            name: 'SolutionDetail',
                            query: {
                              _post: blog.uuid,
                              text: blog.slug,
                            },
                          }"
                        > -->
                      <b-card-img
                        :src="assetUrl + blog.image"
                        class="img-fluid card-img-top"
                        :alt="blog.title"
                      ></b-card-img>
                    </b-link>
                    <b-card-body class="body-list">
                      <b-card-title>
                        <!-- <h2> -->
                          <b-link
                            :to="{
                              name: 'SolutionDetail',
                              query: {
                                text: blog.slug,
                              },
                            }"
                          >
                            {{ blog.title }}
                          </b-link>
                        <!-- </h2> -->
                      </b-card-title>
                      <div class="post-meta">
                        <div class="post-date">
                          <b-link
                            ><b-icon
                              class="calendar-icon"
                              icon="calendar2-week"
                            ></b-icon
                            ><span>{{ blog.date }}</span></b-link
                          >
                        </div>
                      </div>
                      <b-card-text class="four-line">
                        {{ blog.short_description }}
                      </b-card-text>
                    </b-card-body>
                    <b-card-footer>
                      <div class="profile d-flex justify-content-between">
                        <div class="blog-author-meta">
                          <div class="author-thumbnail">
                            <b-img
                              :src="assetUrl + blog.profile"
                              :alt="blog.writer"
                              class="img-fluid"
                            ></b-img>
                          </div>
                          <div class="blog-author-name">
                            <h5>{{ blog.writer }}</h5>
                          </div>
                        </div>
                        <div class="post-read-more">
                          <b-link
                            :to="{
                              name: 'SolutionDetail',
                              query: {
                                text: blog.slug,
                              },
                            }"
                            >Read More<b-icon
                              class="arrow-right"
                              icon="arrow-right"
                            ></b-icon
                          ></b-link>
                          <!-- <b-link 
                              :to="{
                                name: 'SolutionDetail',
                                query: {
                                  _post: blog.uuid,
                                  text: blog.slug,
                                },
                              }"
                              >Read More<b-icon class="arrow-right" icon="arrow-right"></b-icon></b-link> -->
                        </div>
                      </div>
                    </b-card-footer>
                  </b-card>
                </div>
              </b-tab>
            </div>
          </b-tabs>
          <!-- </b-card> -->
        </div>
      </div>
    </div>
    <div class="paginations d-none">
      <b-container>
        <b-pagination
          v-model="currentPage"
          pills
          :total-rows="rows"
          :hide-ellipsis="true"
          :per-page="perPage"
        >
        </b-pagination>
      </b-container>
    </div>
  </div>
</template>
<script>
import breadcrumb from "@core/components/breadcrumb/Breadcrumb";
import AdCardPost from "@views/team/components/ad-card-post/AdCardPost";
import AdSearchBox from "@views/blog/components/ad-search-box/AdSearchBox";
import AdCategoryBox from "@views/blog/components/ad-category-box/AdCategoryBox";
// import AdTagBox from "@views/blog/components/ad-tag-box/AdTagBox";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Blog",
  components: {
    breadcrumb,
    AdCardPost,
    AdSearchBox,
    // AdCategoryBox,
    // AdTagBox
  },
  data() {
    return {
      title: "News",
      rows: 10,
      currentPage: 1,
      perPage: 1,
      background: require("@assets/images/blog/blog-04.jpg"),
      assetUrl: process.env.VUE_APP_ASSET_URL + "/",
      breadcrumbs: [
        {
          text: "Home",
          href: "#",
        },
        {
          text: "Blog",
          href: "#",
          active: true,
        },
      ],
      category_items: [
        { id: 1, item: "Business", number: "2", link: "#" },
        { id: 2, item: "Consultant", number: "4", link: "#" },
        { id: 3, item: "Education", number: "2", link: "#" },
        { id: 4, item: "Help", number: "2", link: "#" },
        { id: 5, item: "Technology", number: "2", link: "#" },
      ],
      tag_items: [
        { id: 1, tag: "charity", link: "#" },
        { id: 2, tag: "donate", link: "#" },
        { id: 3, tag: "help", link: "#" },
        { id: 4, tag: "ux", link: "#" },
        { id: 5, tag: "water", link: "#" },
      ],
      blogList: [
        {
          id: 1,
          blog_category: {
            title: "Accusantium ut lorem",
            remark: "Dolorem veniam omni",
          },
          title: "Excepturi vitae culp",
          slug: "excepturi-vitae-culp",
          keyword: "Magni ad aut quasi l",
          first_description: "<p>Anim dolorem illo au</p>\n",
          writer: "Anim dolorem illo au",
          date: "0000-00-00",
          image: "images/blogs/644648ddd8787.png",
          profile: "images/blogs/644648dfcfd89.png",
        },
      ],
    };
  },

  methods: {
    ...mapActions(["fetchBlog", "fetchBlogDetail"]),
  },
  computed: {
    ...mapGetters(["blog", "blogDetail"]),
  },
  mounted() {
    this.fetchBlog({
      filter: {
        locale: this.$router.currentRoute.params.locale
          ? this.$router.currentRoute.params.locale
          : "en",
      },
    });

    this.fetchBlogDetail({
      filter: {
        locale: this.$router.currentRoute.params.locale
          ? this.$router.currentRoute.params.locale
          : "en",
      },
      slug: this.$router.currentRoute.query.text
        ? this.$router.currentRoute.query.text
        : null,
      // slug: this.$router.currentRoute.query._post
      //   ? this.$router.currentRoute.query._post
      //   : null,
    });
  },
  watch: {
    $route(to, from) {
      if (from.params.locale && to.params.locale !== from.params.locale) {
        this.fetchBlog({
          filter: {
            locale: this.$router.currentRoute.params.locale
              ? this.$router.currentRoute.params.locale
              : "en",
          },
        });
      }
    },
    $route(to, from) {
      if (from.params.locale && to.params.locale !== from.params.locale) {
        this.fetchBlogDetail({
          filter: {
            locale: this.$router.currentRoute.params.locale
              ? this.$router.currentRoute.params.locale
              : "en",
          },
          uuid: this.$router.currentRoute.query
            ? this.$router.currentRoute.query._post
            : null,
        });
      }
    },
  },

  // seo
  metaInfo() {
    let locale = localStorage.getItem("_locale");
    locale = locale ? JSON.parse(locale) : null;
    return {
      title:
        this.blog && this.blog.breadcrumb && this.blog.breadcrumb.title
          ? this.blog.breadcrumb.title
          : "Blog",
      titleTemplate:
        "%s | " +
        (this.$root.general.settings.app_name
          ? this.$root.general.settings.app_name
          : "Aditi Wesbite"),
      htmlAttrs: {
        lang: locale && locale.title ? locale.title : "en",
      },
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            this.blog &&
            this.blog.breadcrumb &&
            this.blog.breadcrumb.description
              ? this.blog.breadcrumb.description
              : this.$root.general.settings.description
              ? this.$root.general.settings.description
              : "",
        },
        {
          vmid: "keyword",
          name: "keyword",
          content:
            this.blog && this.blog.breadcrumb && this.blog.breadcrumb.keyword
              ? this.blog.breadcrumb.keyword
              : "",
        },
      ],
    };
  },
};
</script>
<style lang="scss">
@import "@assets/scss/pages/blog/blog.scss";
</style>
