<template>
  <div class="service-page">
    <Breadcrumb
      :title="service && service.breadcrumb && service.breadcrumb.title ? service.breadcrumb.title : breadcrumbTitle"
      :backgroundImg="service && service.breadcrumb && assetUrl+service.breadcrumb.image ? assetUrl+service.breadcrumb.image : breadcrubBackground"
    ></Breadcrumb>
    <serviceList :serviceLists="serviceList" ></serviceList>
    <Counter :counters="counterList"> </Counter>
    <section class="service-progress sy d-none">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 center">
            <div class="content-center">
              <div class="box-content">
                <h5 class="head-title">{{ headTitle }}</h5>
                <div class="title">
                  <h2>{{title}}</h2>
                </div>
                <div class="descript">
                  <p>
                    {{descript}}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="video">
              <div class="thumbnail image">
                <img :src="thumbnail" :alt="title" class="img-fluid" />
              </div>
              <div class="btn-play bg-img" :style="{ 'background-image': 'url(' + thubnailPlay + ')' }" >
                <b-link href="#" class="play" @click="$bvModal.show('bv-modal-video')">
                  <b-icon-play-fill></b-icon-play-fill>
                </b-link>
                <b-modal modal-class="show-up" :id="`bv-modal-video`" centered hide-footer >
                  <div class="video-content pt-4">
                    <b-embed type="iframe" aspect="16by9" src="https://www.youtube.com/embed/tuyWJVGPRXQ" allowfullscreen poster="https://i.ytimg.com/vi_webp/mRGhscirX1Q/maxresdefault.webp"> </b-embed>
                    <!-- <iframe w=100 src="https://www.youtube.com/embed/tuyWJVGPRXQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                  </div>
                </b-modal>
              </div>
            </div>
            <!-- <section class="video sy-4">
                <div class="video-content pt-4">
                    <div class="image">
                        <img :src="thumbnail" alt="" class="img-fluid">
                    </div>
                    <div class="content-btn-play">
                        <a class="video-icon" id="show-btn" :style="{ 'background-image': 'url(' + thubnailPlay + ')' }">
                            <span class="icon">
                                <b-icon-play-fill></b-icon-play-fill>
                            </span>
                        </a>
                        <b-modal modal-class="show-up" :id="`bv-modal-video`" centered hide-footer >
                          <div class="video-content pt-4">
                            <b-embed type="iframe" aspect="16by9" src="" allowfullscreen poster=""> </b-embed>
                          </div>
                        </b-modal>
                    </div>
                </div>
            </section> -->
          </div>
        </div>
      </div>
    </section>
    <Testimonail :Testimonails="testimonial.testimonial" :Clients="testimonial.client">
    </Testimonail>
  </div>
</template>
<script>
  import Breadcrumb from '@/core/components/breadcrumb/Breadcrumb.vue';
  import serviceList from '@/components/service/Service.vue'
  import Testimonail from '@/components/testimonail/Testimonail.vue';
  import Counter from '@/components/counter/Counter.vue';
  import { mapActions, mapGetters } from 'vuex';
  export default {
    components: { Breadcrumb, Testimonail, Counter,serviceList },
    data() {
      return {
        assetUrl: process.env.VUE_APP_ASSET_URL + '/',
        headTitle: 'POPULAR IT SERVICES',
        title: 'Our Professional Solutions For IT Business',
        descript:
          'Sed ut perspiciatis omnis natus error sit voluptatem accusan doloremque laudantium totam rem aperiam',
        thumbnail: require('@/assets/images/service/aditi-1.jpg'),
        thubnailPlay: require('@/assets/images/service/aditi-1.jpg'),
        breadcrumbTitle: 'Services',
        breadcrubBackground: require('@/assets/images/breadcrumb/Artboard4.jpg'),
        // BreadcrumbBackground: { backgroundImage: "url( require('@/assets/images/breadcrumb/Artboard 7.jpg') )" }
        clientList: [
          {
            title: 'Auto Space',
            link: 'www.google.com',
            logo: require('@/assets/images/client/Artboard 31.png'),
          },
          {
            title: 'Camsolution',
            link: 'www.google.com',
            logo: require('@/assets/images/client/Artboard 1.png'),
          },
          {
            title: 'Camsolution',
            link: 'www.google.com',
            logo: require('@/assets/images/client/Artboard 2.png'),
          },
          {
            title: 'Camsolution',
            link: 'www.google.com',
            logo: require('@/assets/images/client/Artboard 4.png'),
          },
          {
            title: 'Camsolution',
            link: 'www.google.com',
            logo: require('@/assets/images/client/Artboard 5.png'),
          },
          {
            title: 'Camsolution',
            link: 'www.google.com',
            logo: require('@/assets/images/client/Artboard 6.png'),
          },
          {
            title: 'Camsolution',
            link: 'www.google.com',
            logo: require('@/assets/images/client/Artboard 25.png'),
          },
          {
            title: 'Camsolution',
            link: 'www.google.com',
            logo: require('@/assets/images/client/Artboard 26.png'),
          },
        ],
        //Testimonail

        TestimonailList: [
          {
            name: 'Tom Jones',
            position: 'General Mananger',
            message:
              'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ',
            profile: require('@/assets/images/teams/profile (1).jpg'),
          },
          {
            name: 'Tom Jones',
            position: 'General Mananger',
            message:
              'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ',
            profile: require('@/assets/images/teams/profile (2).jpg'),
          },
          {
            name: 'Tom Jones',
            position: 'General Mananger',
            message:
              'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ',
            profile: require('@/assets/images/teams/profile (3).jpg'),
          },
          {
            name: 'Tom Jones',
            position: 'General Mananger',
            message:
              'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ',
            profile: require('@/assets/images/teams/profile (3).jpg'),
          },
        ],
        //Counter
        counterList: [
          {
            icon: require('@/assets/images/icon/icon-3.png'),
            title: 'Project Complete',
            count: '100',
          },
          {
            icon: require('@/assets/images/icon/icon-4.png'),
            title: 'Expert Consultants',
            count: '200',
          },
          {
            icon: require('@/assets/images/icon/icon-2.png'),
            title: 'Project Complete',
            count: '100',
          },
          {
            icon: require('@/assets/images/icon/icon-1.png'),
            title: 'Project Complete',
            count: '100',
          },
        ],
        serviceList: [
          {
            title: 'Web Development',
            icon: require('@/assets/images/service/value.png'),
            shortDescript:
              'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            descript:
              'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            thubmnail: require('@/assets/images/service/service-2.jpg'),
            image: require('@/assets/images/service/service.jpg'),
          },
          {
            title: 'Digital Marketing',
            icon: require('@/assets/images/service/value.png'),
            shortDescript:
              'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            descript:
              'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            thubmnail: require('@/assets/images/service/service-3.jpg'),
            image: require('@/assets/images/service/service.jpg'),
          },
          {
            title: 'Mobile Application',
            icon: require('@/assets/images/service/value.png'),
            shortDescript:
              'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            descript:
              'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            thubmnail: require('@/assets/images/service/service-4.jpg'),
            image: require('@/assets/images/service/service.jpg'),
          },
          {
            title: 'Graphic Design',
            icon: require('@/assets/images/service/value.png'),
            shortDescript:
              'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            descript:
              'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            thubmnail: require('@/assets/images/service/service.jpg'),
            image: require('@/assets/images/service/service.jpg'),
          },
        ],

      };
    },
    methods: {
      // ...mapActions(['fetchHome']),
      ...mapActions(['fetchTestimonial','fetchService']),
    },
    computed: {
      // ...mapGetters(['home']),
      ...mapGetters(['testimonial','service']),
    },
    mounted() {
      this.fetchTestimonial({
        filter: {
          locale: this.$router.currentRoute.params.locale
            ? this.$router.currentRoute.params.locale
            : 'en',
        },
      });
      this.fetchService({
        filter: {
          locale: this.$router.currentRoute.params.locale
            ? this.$router.currentRoute.params.locale
            : 'en',
        },
      });
      
    },

    // seo
    metaInfo () {
      let locale = localStorage.getItem('_locale')
      locale = locale ? JSON.parse(locale) : null
      return {
        title: (this.service && this.service.breadcrumb && this.service.breadcrumb.title
          ? this.service.breadcrumb.title
          : 'Service'),
        titleTemplate:
                '%s | ' +
                (this.$root.general.settings.app_name
                  ? this.$root.general.settings.app_name
                  : 'Aditi Wesbite'),
        htmlAttrs: {
          lang: locale && locale.title ? locale.title : 'en'
        },
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: (this.service && this.service.breadcrumb && this.service.breadcrumb.description)
              ? this.service.breadcrumb.description
              : (this.$root.general.settings.description
                ? this.$root.general.settings.description
                : '')
          },
          {
            vmid: 'keyword',
            name: 'keyword',
            content: (this.service && this.service.breadcrumb && this.service.breadcrumb.key_word)
              ? this.service.breadcrumb.key_word
              : ''
          }
        ]
      }
    },

  };
</script>
<style lang="scss">
  @import '@assets/scss/pages/service';
</style>
