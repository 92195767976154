<template>
<div class="ad-category-box">
  <div class="category-box">
    <div class="title-wrap">
      <div class="icon">
        <b-icon icon="dash-lg"></b-icon>
        <b-icon icon="dot"></b-icon>
      </div>
      <h2 class="title">Categories</h2>
    </div>
    <div
        class="category-item"
        v-for="(item,id) in categories"
        :key="id"
    >
      <div class="item-wrap">
        <b-link :href="item.link">{{item.item}}</b-link>
        <span>({{item.number}})</span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "AdCategoryBox",
  props:['categories'],
}
</script>

<style lang="scss">
@import "@assets/scss/pages/blog/components/category-box.scss";
</style>