<template>
  <div class="footer">
    
    <!-- :style="{ backgroundImage: `url(` + serviceBackground + `)` }" -->
    <div
      class="foot-direction sy ">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="title">
              <h3>
                {{ $root.translates && $root.translates.banner_footer ? $root.translates.banner_footer:"Ready To Get Free Consulations For Any Kind Of It Solutions ?" }}
              </h3>
            </div>
          </div>
          <div class="col-md-6 center">
            <div class="content-center">
              <div class="box-content">
                <Button :link="{ name:'Consultant'}" class="hover-white btn-quote me-3" title="Get a Quote">
                </Button>
                <Button :link="{ name:'Consultant'}" class="no-bg" title="Read More"> </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="footer-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12 mb-3">
            <div class="image logo">
              <b-img
                :src=" $root.settings.logo 
                ? $root.settings.logo
                : require('@/assets/images/logo/main-logo.png')"
                fluid
                :alt="$root.settings.app_name"
              >
              </b-img>
            </div>
            <div class="short-descrite ">
              <p>
               {{$root.translates && $root.translates.des_footer ? $root.translates.des_footer : "Aditi is a professional software development company joined by the most talented team who are really passionate about what they have been doing and offer professional IT solutions expertise to help our customer grow."}}
              </p>
            </div>
             <form id="form" novalidate autocomplete="off" autofocus class="needs-validation mt-5 subcrib-form" 
              enctype="multipart/form-data" @submit.prevent="onSubmit($event)">
                <input 
                  v-model="formRequest.email_address"
                  :class="{
                    'is-invalid': $v.formRequest.email_address.$error,
                  }"
                  type="email" class="form-control" id="InputEmail"
                  placeholder="email address" 
                />
                <button type="submit" class="btn-subcrib"> 
                  <b-icon-chat-square-text-fill></b-icon-chat-square-text-fill>
                </button>
             </form> 
          </div>    
          <div class="col-lg-4 col-md-6">
           <div class="foot-list">
            <div class="item">
              <div class="title">
                <h4>Information</h4>
              </div>
              <ul class="nav sitemap flex-column">
                <li class="nav-item">
                  <b-link :to="{ name: 'Service' }">
                    <b-icon-chevron-double-right></b-icon-chevron-double-right>
                    Service
                  </b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="{ name: 'Portfolio' }">
                    <b-icon-chevron-double-right></b-icon-chevron-double-right>
                    Portfolio
                  </b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="{ name: 'Career' }">
                    <b-icon-chevron-double-right></b-icon-chevron-double-right>
                    Career
                  </b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="{ name: 'Condition' }">
                    <b-icon-chevron-double-right></b-icon-chevron-double-right>
                    Condition
                  </b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="{ name: 'Privacy' }">
                    <b-icon-chevron-double-right></b-icon-chevron-double-right>
                    Privacy
                  </b-link>
                </li>
                <!-- <li class="nav-item">
                  <b-link :to="{ name: 'Blog' }">
                    <b-icon-chevron-double-right></b-icon-chevron-double-right>
                    Blog
                  </b-link>
                </li> -->
              </ul>
            </div>
            <div class="item">
              <div class="title">
                <h4>Company</h4>
              </div>
              <ul class="nav sitemap flex-column">
                <li class="nav-item">
                  <b-link :to="{ name: 'About' }">
                    <b-icon-chevron-double-right></b-icon-chevron-double-right>
                    About Us
                  </b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="{ name: 'Consultant' }">
                    <b-icon-chevron-double-right></b-icon-chevron-double-right>
                    Consultant
                  </b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="{ name: 'Service' }">
                    <b-icon-chevron-double-right></b-icon-chevron-double-right>
                    Service
                  </b-link>
                </li>
        
                <li class="nav-item">
                  <b-link :to="{ name: 'team' }">
                    <b-icon-chevron-double-right></b-icon-chevron-double-right>
                    Team
                  </b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="{ name: 'Faq' }">
                    <b-icon-chevron-double-right></b-icon-chevron-double-right>
                    FAQ
                  </b-link>
                </li>
              </ul>
            </div>
           </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="item">
              <div class="title">
                <h4>Contact Us</h4>
              </div>
              <div class="foot-info">
                <address>
                  <b-icon-geo-alt-fill></b-icon-geo-alt-fill> {{$root && $root.settings && $root.settings.address}}
                </address>
                <p>
                  <a :href="'mailto:'+($root && $root.settings && $root.settings.email)"
                    ><b-icon-envelope class="me-2"></b-icon-envelope>
                    {{$root && $root.settings && $root.settings.email}}</a
                  >
                </p>
                <p>
                  <a :href="'tel:'+ ($root && $root.settings && $root.settings.phone)"
                    ><b-icon-telephone class="me-2"></b-icon-telephone>
                    {{$root && $root.settings && $root.settings.phone}}</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="title py-2 text-center">
          <p>{{$root && $root.settings && $root.settings.copyright}}</p>
        </div>
      </div>
    </section>
    <b-button v-if="scrolledToBottom" @click="scrollToTop" class="back-to-top"
    ><b-icon-arrow-up></b-icon-arrow-up></b-button>

    <!-- <div class="float-end">
      <div class="ph_tele d-flex justify-content-center align-items-center ">
        <div id="phone_call" class="d-flex justify-content-center align-items-center m-1">
          <a :href="'tel:'+ ($root && $root.settings && $root.settings.phone)" target="_blank">
            <b-icon-telephone-fill class="me-2"></b-icon-telephone-fill>
          </a>
        </div>
        <div id="telegram_chat">
          <a :href="($root && $root.settings && $root.settings.telegram_link)" target="_blank">
            <b-icon-telegram class="me-2"></b-icon-telegram>
          </a>
        </div>
      </div>
    </div> -->

    <div>
    <div class="fabs" @click="toggleBtn">
      <div class="action">
        <!-- <i class="fas fa-plus" :class="{ 'fa-times': isOpen }"></i> -->
        <i :class="{ 'fa-times': isOpen }">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-three-dots-vertical"
            viewBox="0 0 16 16"
          >
            <path
              d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
            />
          </svg>
        </i>
      </div>

      <div class="btns" :class="{ open: isOpen }">
        <div class="btn">
          <div class="fb" id="messager" v-bind:style="{ display: isVisible ? 'block' : 'none' }">
            <!-- Messenger Chat Plugin Code --> 
            <div id="fb-root"></div>
            <!-- Your Chat Plugin code -->
            <div id="fb-customer-chat" class="fb-customerchat"></div>
          </div>
        </div>
        <!-- phone -->
        <a :href="'tel:'+ ($root && $root.settings && $root.settings.phone)" target="_blank" class="btn" style="background: #b10a47" >
          <i>
            <b-icon-telephone-fill class="me-2"></b-icon-telephone-fill>
          </i>
        </a>
         <!-- telegram -->
        <a
          :href="($root && $root.settings && $root.settings.telegram_link)" target="_blank"
          class="btn"
          style="background: #36aee1"
        >
          <i>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="38"
              height="38"
              fill-rule="evenodd"
              clip-rule="evenodd"
              image-rendering="optimizeQuality"
              shape-rendering="geometricPrecision"
              text-rendering="geometricPrecision"
              viewBox="0 0 4335 4335"
              id="logotype"
            >
              <defs>
                <linearGradient
                  id="a"
                  x1="2149.16"
                  x2="2149.16"
                  y1="177.36"
                  y2="4241.78"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#36AEE1"></stop>
                  <stop offset="1" stop-color="#36AEE1"></stop>
                </linearGradient>
              </defs>
              <path
                fill="url(#a)"
                d="M2149 177c1122 0 2032 910 2032 2032s-910 2032-2032 2032S117 3331 117 2209 1027 177 2149 177z"
              ></path>
              <path
                fill="#fff"
                d="m3194 1496-319 1503-215 39-443-325-230 228-338-580 368-543 953-335z"
              ></path>
              <path
                fill="#f2f8fb"
                d="M2002 2550c4 12 95 72 102 75l-2-9 580-361c12-8 19-11 30-19 76-50 169-103 244-153l91-57c6-4 8-6 16-10 14-8 5-6 21-9l11-51c5-27 21-81 19-105-4-9 1-8-30 12-12 8-23 14-35 22l-212 132c-132 87-287 177-421 263l-212 132c-24 15-46 29-70 44l-105 65c-42 25-33 16-30 28z"
              ></path>
              <path
                fill="#f5fafd"
                d="M2104 2625c4 10 37 30 48 39 19 13 36 25 52 38 3-12 1-7 8-14l226-141c9-6 17-10 26-16 10-6 16-9 26-16l562-349 10-39 14-66 10-53c-17 3-8 1-21 9-8 5-10 6-16 10l-91 57c-75 50-168 103-244 153-12 8-18 11-30 19l-580 361 2 9z"
              ></path>
              <path
                fill="#fefeff"
                d="M2493 2913c3 7 14 13 22 19 16 11 74 52 84 65 12 2 81 56 90 63 48 35 61 29 121 31 18-17 19-5 42-39 13-20 13-33 23-53l36-171 26-121c4-25 13-48 12-72l-2-12-233 144c-19 13-38 23-58 36l-142 89c-9 5-19 7-21 21z"
              ></path>
              <path
                fill="#f0f7fc"
                d="M1940 2504c5 10 49 40 62 46-3-11-12-3 30-28l105-65c24-15 45-28 70-44l212-132c134-86 289-177 421-263l212-132c12-8 23-14 35-22 31-20 26-20 30-12 11-12 11-43 16-61 2-14 6-21 3-34-8-11 4-9-11-9-6 9-62 42-74 49l-372 233c-126 82-267 164-393 246l-316 198c-7 4-24 11-27 18-5 9-14-9-1 12z"
              ></path>
              <path
                fill="#fbfdfe"
                d="M2371 2822c0 1 1 1 1 2 1 6 67 49 77 60 4-20 6-13 45-38 6-4 12-7 17-10s11-7 16-10l33-20c90-54 187-120 277-173l98-61c30-19 22-22 29-8 9-11 9-33 14-48l14-64v-17c-10-12 0-7-12-4l-223 140c-9 6-14 8-21 13-105 65-224 143-328 205-13 8-24 16-39 23 4 6 0 2 1 11z"
              ></path>
              <path
                fill="#2199cc"
                d="m2131 2801 174 74c33 14 271 123 295 122-10-13-68-54-84-65-8-6-20-12-22-19-19-9-27-18-44-29-11-11-76-54-77-60 0-1-1-1-1-2-17-7-28-18-42-28-11-8-25-18-37-27-17-13-58-46-75-53-14 14-25 24-42 43-14 16-33 26-44 45z"
              ></path>
              <path
                fill="#f7fbfd"
                d="m2204 2702 12 11c17 8 58 41 75 53 12 9 26 20 37 27 14 11 25 21 42 28-1-9 2-5-1-11 15-7 26-15 39-23 104-62 223-140 328-205 8-5 12-7 21-13l223-140c12-3 1-8 12 4l12-40c3-12 5-27 9-37l18-87 21-104-562 349c-10 7-16 9-26 16-9 6-17 10-26 16l-226 141c-7 7-5 2-8 14z"
              ></path>
              <path
                fill="#edf5fb"
                d="m3045 1344-157 61-127 49-216 85-97 36-272 105-72 27-197 76-125 48-149 58-138 54-87 34-95 36-92 36c-7 5-58 26-67 29-5 6-120 71-14 124 27 13 469 153 508 160 22-9 53-30 74-44l366-231c187-118 404-250 584-369 43-29 122-88 158-62 2 24-26 40-46 57l-172 157c-10 9-20 15-29 24l-57 53c-13 11-17 15-30 27l-575 520 6 9 11 2c-13-21-4-3 1-12 4-7 20-14 27-18l316-198c126-82 267-164 393-246l372-233c13-8 69-40 74-49 14 0 2-2 11 9 3-4 1-1 5-9l21-98 26-122c7-39 20-76 12-117-7-36-23-62-52-75-41-17-66-1-102 8z"
              ></path>
              <path
                fill="#c8daea"
                d="m1649 2361 39 128 41 136 37 121 36 117c5 8 18 56 22 68 9 30 12 52 43 51l22-2 41-479c-2-3-3-7-6-9l575-520c13-13 17-16 30-27l57-53c9-9 19-15 29-24l172-157c20-17 48-34 46-57-37-26-115 33-158 62-180 119-397 251-584 369l-366 231c-21 14-52 35-74 44z"
              ></path>
              <path
                fill="#a9c9dd"
                d="M1867 2983v3c28 2 50 1 73-11 32-16 30-29 48-34 3-5 61-67 70-69 8-16 40-41 54-55l20-16c10-19 29-29 44-45 17-19 28-29 42-43l-12-11c-17-14-34-25-52-38-12-8-44-29-48-39-7-3-98-64-102-75-12-6-57-36-62-46l-11-2-41 479-22 2z"
              ></path>
              <path
                fill="#fcfdfe"
                d="M2449 2883c17 12 25 21 44 29 2-14 12-16 21-21l142-89c20-13 39-24 58-36l233-144 2 12c7-8 5-14 9-25l6-47c-7-14 1-12-29 8l-98 61c-90 53-187 119-277 173l-33 20c-5 3-11 7-16 10s-11 6-17 10c-39 25-42 18-45 38z"
              ></path>
            </svg>
          </i>
        </a>

       

      </div>
    </div>
    </div>


  </div>

</template>
<script>
import Button from '@/core/components/button/Button';
// import func from 'vue-editor-bridge';
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import api from "@/api";
  export default {
    name: 'Footer',
    components: {
      Button,
    },
    mixins: [validationMixin],
    setup() {
        function toastSuccess(message) {
          this.$toast.success(message);
        }
        function toastWarning(message) {
          this.$toast.warning(message);
        }
        function toastInfo(message) {
          this.$toast.info(message);
        }
        function toastError(message) {
          this.$toast.error(message);
        }
        function toastDefault(message) {
          this.$toast(message);
        }
        return {
          toastDefault,
          toastSuccess,
          toastInfo,
          toastWarning,
          toastError,
        };
    },
    data() {
      return {
        assetUrl: process.env.VUE_APP_ASSET_URL + '/',
        serviceBackground: require('@/assets/images/cta_bg1.png'),
        visible: false,
        scrolledToBottom: false,
        formRequest: {
          email_address: null,
        },
    
        isOpen: false,

        isVisible: false,

      };
    },
    methods: {
      fbCustomerChat(){
        var chatbox = document.getElementById('fb-customer-chat');
        chatbox.setAttribute("page_id", "107914602101404");
        chatbox.setAttribute("attribution", "biz_inbox");

        window.fbAsyncInit = function() {
          FB.init({
            xfbml : true,
            version : 'v17.0'
          });
        };

        (function(d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s); js.id = id;
          js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      },
        toggleBtn() {
        const btns = document.querySelector('.btns');
        const btn = document.querySelectorAll('.btns a');
        this.isOpen = !this.isOpen;
        this.isVisible = !this.isVisible;
        if (this.isOpen) {
          btns.classList.add('open');
          btn.forEach((e, i) => {
            setTimeout(() => {
              const bottom = 50 * i;
              e.style.bottom = bottom + 'px';
            }, 130 * i);
          });
        } else {
          btns.classList.remove('open');
          btn.forEach((e) => {
            e.style.bottom = '0px';
          });
        }
      },
      scroll () {
        window.onscroll = (e) => {
          if(window.scrollY > 300){
              this.scrolledToBottom = true;
          }else if(window.scrollY < 300){
              this.scrolledToBottom = false;
          }
        }
      },
      scrollToTop(){
          window.scrollTo({
              top: 0,
              behavior: 'smooth'
          });
      },
      onSubmit() {
        this.$root.loading = true;
        this.$root._loading();
        // stop here if form is invalid
        this.$v.$touch();
        console.log(this.$v.$invalid);
        if (this.$v.$invalid) {
            this.$root.loading = false;
            this.$root._loading();
          if(!this.$v.formRequest.email_address.required){
            this.toastError("Please enter email address");
            return false;
          }else if(!this.$v.formRequest.email_address.email_address){
            this.toastError("Please enter invalid email address !");
            return false;
          }
        }
        api.footer
          .fetchFooter(this.getFormData(this.formRequest))
          .then((response) => {
            this.toastSuccess(response.data.message);
            this.$root.loading = false;
            this.$root._loading();
            this.formRequest= {
              email_address: null,
            };
            this.$v.$reset();
          })
          .catch((error) => {
            const hasError = error.response.data.error;
            if (hasError && hasError.validate) {
              const errorValidate = hasError.validate;
              this.onValidateError(errorValidate);
              this.loading = false;
              if (!errorValidate) {
                this.formRequest = {
                  email_address: null,
                  message: null,
                };
                this.toastSuccess(
                  "Thank You for contact us, We will contact back soon."
                );
              }
            } else {
              this.toastError("You cannot sent request successfully!");
            }
          })
          .finally(() => {
            this.$root.loading = false;
            this.$root._loading();
          });
      },
      getFormData(data) {
        const formData = new FormData();
        formData.append("email_address", data.email_address);
        return formData;
      },
      
      onValidateError(errorValidate) {
        if (errorValidate.email_address) {
          this.toastError(errorValidate.email_address[0]);
        }
      },
    },
     validations: {
      formRequest: {
        email_address: { required, email },
      },
    },
    mounted () {
      this.scroll();
      this.fbCustomerChat();
    },  
  };
</script>

<style lang="scss">
  @import '../../assets/scss/layout/footer';
</style>