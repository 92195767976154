<template>
  <div id="header" class="sticky-top">
    <div class="top-bar">
      <div class="overlay"></div>
        <div class="container top-head">
          <div class="info d-flex align-items-center">
            <p><a href="mailto:someone@example.com"><b-icon-envelope></b-icon-envelope>info@aditiasia.com</a></p>
            <p><a href="tel:123-456-7890"><b-icon-telephone></b-icon-telephone>123-456-7890</a></p>
          </div>
          <div class="social">
            <b-navbar-nav class="text nav flex-row justify-content-center">
            <b-nav-item :to="{name:'#'}"><b-icon-facebook></b-icon-facebook></b-nav-item>
            <b-nav-item :to="{name:'#'}"><b-icon-instagram></b-icon-instagram></b-nav-item>
            <b-nav-item :to="{name:'#'}"><b-icon-twitter></b-icon-twitter></b-nav-item>
            <b-nav-item :to="{name:'#'}"><b-icon-linkedin></b-icon-linkedin></b-nav-item>
            </b-navbar-nav>
          </div>
        </div>
    </div>
    <section class="header-group">
      <div class="container">
        <b-navbar toggleable="lg">
        <div class="logo">
          <b-link :to="{ name: 'Home' }">
            <b-img
            :src=" $root.settings.logo 
                ? $root.settings.logo
                : require('@/assets/images/logo/main-logo.png')"
              fluid
              alt="Camsolution Logo"
            >
            </b-img>
          </b-link>
        </div>
        <b-navbar-toggle target="nav-collapse"><b-icon-list></b-icon-list></b-navbar-toggle>
        <b-collapse id="nav-collapse" class="justify-content-center" is-nav>
          <b-navbar-nav class="text nav">
            <b-nav-item :to="{ name: 'Home' }">{{$root.translates && $root.translates.menu_home ? $root.translates.menu_home : "Home"}}</b-nav-item>
            <b-nav-item :to="{ name: 'Service' }">{{$root.translates && $root.translates.service ? $root.translates.service : "Service"}}</b-nav-item>
            <b-nav-item :to="{ name: 'About' }">{{$root.translates && $root.translates.about ? $root.translates.about : "About"}}</b-nav-item>
            <!-- <ul class="nav nav-pills">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-expanded="false"
                  >About us</a
                >
                <ul class="dropdown-menu">
                  <li>
                    <b-link class="dropdown-item" :to="{ name: 'Car-list' }"
                      >Campany</b-link
                    >
                  </li>
                  <li>
                    <b-link class="dropdown-item" :to="{ name: 'Car-list' }"
                      >Team</b-link
                    >
                  </li>
                  <li>
                    <b-link class="dropdown-item" :to="{ name: 'Car-list' }"
                      >FAQ</b-link
                    >
                  </li>
                </ul>
              </li>
            </ul> -->
            <b-nav-item :to="{ name: 'team' }">{{$root.translates && $root.translates.teams ? $root.translates.teams : "Teams"}}</b-nav-item>
            <b-nav-item :to="{ name: 'Blog' }">{{$root.translates && $root.translates.menu_blog ? $root.translates.menu_blog : "Blog"}}</b-nav-item>
            <b-nav-item :to="{ name: 'Portfolio' }">{{$root.translates && $root.translates.portfolio ? $root.translates.portfolio : "Portfolio"}}</b-nav-item>

            <b-nav-item class="btn-consultant d-lg-none" :to="{ name: 'Consultant' }">{{$root.translates && $root.translates.consultancy ? $root.translates.consultancy : "Consultancy"}}<b-icon-arrow-right-short></b-icon-arrow-right-short></b-nav-item>
            <b-nav-item-dropdown
              :text="
                $root.general &&
                $root.general.locales &&
                $root.general.locales.currentActive
                  ? $root.general.locales.currentActive.title
                  : 'EN'
              "
              class="lang d-lg-none"
          >
            <b-dropdown-item
                v-for="locale in $root.general.locales.all"
                :key="locale.title"
                @click="onSwitch(locale)"
            >{{ locale.language }}</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
        <b-navbar-nav class="right-menu nav ">
          <b-nav-item class="btn-consultant " :to="{ name: 'Consultant' }">{{$root.translates && $root.translates.consultancy ? $root.translates.consultancy : "Consultancy"}} <b-icon-arrow-right-short></b-icon-arrow-right-short></b-nav-item>
          <b-nav-item-dropdown
              :text="
                $root.general &&
                $root.general.locales &&
                $root.general.locales.currentActive
                  ? $root.general.locales.currentActive.title
                  : 'EN'
              "
              class="lang"
          >
            <b-dropdown-item
                v-for="locale in $root.general.locales.all"
                :key="locale.title"
                @click="onSwitch(locale)"
            >{{ locale.language }}</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-navbar>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: 'Please select an option' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Selected Option' },
        { value: { C: '3PO' }, text: 'This is an option with object value' },
        { value: 'd', text: 'This one is disabled', disabled: true }
      ]
    };
  },
  methods: {
    onSwitch(locale) {
      const currentLocale = locale && locale.title ? locale.title : "en";
      this.setLocaleTtoLocaleStorage(locale);
      if (this.$router.currentRoute.name !== "home") {
        if (
            this.$router.currentRoute.params &&
            this.$router.currentRoute.params.locale !== currentLocale
        ) {
          document.documentElement.setAttribute("lang", currentLocale);
          this.$router.push({
            name: this.$router.currentRoute.name,
            params: {
              locale: currentLocale,
            },
            query: this.$router.currentRoute.query,
          });
        }
      } else {
        if (
            this.$router.currentRoute.params &&
            this.$router.currentRoute.params.locale !== currentLocale
        ) {
          document.documentElement.setAttribute("lang", currentLocale);
          this.$router.push({
            name: this.$router.currentRoute.name,
            params: {
              locale: currentLocale,
            },
            query: this.$router.currentRoute.query,
          });
        }
      }
    },
    setLocaleTtoLocaleStorage(locale) {
      if (localStorage.getItem("_locale")) {
        localStorage.removeItem("_locale");
      }
      localStorage.setItem("_locale", JSON.stringify(locale));
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/layout/header";
</style>
