<template>
  <div class="career-page">
    <section class="section-career">
      <div class="container">
        <div class="row form-gorup sy"
          v-if="career && career.careerList && career.careerList.length > 0"
        >
          <div class="col-sm-6 center"
            v-for="(item, index) in career.careerList"
            :key="'item' + index"
          >
            <div class="content-center">
              <div class="box-content w-100">
                <div class="content">
                  <h5 class="head-title">{{ item.title }}</h5>
                  <div class="title">
                    <router-link :to="{ name: 'CareerDetail' }">
                      <h4>{{ item.position }}</h4>
                    </router-link>
                  </div>
                  <div class="descript">
                    <p class="three-line"> {{ item.short_description }} </p>
                  </div>
                </div>

                <div class="form-icon">
                  <hr />
                  <div class="main d-flex justify-content-between">
                    <div class="content">
                      <p class="mb-2 text-uppercase">
                        <b-icon icon="geo-alt-fill"></b-icon> &nbsp; Location
                      </p>
                      <h6 class="location">{{ item.location }}</h6>
                    </div>
                    <div class="content">
                      <p class="mb-2 text-uppercase">
                        <b-icon icon="briefcase-fill"></b-icon> &nbsp; How Work
                      </p>
                      <h6 class="how-work">{{ item.how_work }}</h6>
                    </div>
                  </div>
                  <hr class="mt-2" />
                </div>

                <div class="group mt-4 d-flex justify-content-between">
                  <div class="date d-flex align-items-center">
                    <div class="elementor-widget-container">
                      {{ item.post_date }}
                    </div>
                  </div>
                  <div class="form-link">
                    <router-link :to="{
                        name: 'CareerDetail',
                        query: {
                          _post: item.uuid,
                          text: item.slug,
                        },
                      }" class="btn-main" >
                      Apply
                      <b-icon-arrow-right-short></b-icon-arrow-right-short>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import Button from "@/core/components/button/Button";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Career",
  components: {
    Button,
  },
  data() {
    return {
      //Blog
      // Caeers: [
      //     {
      //         company_name: 'by Nebula Corp',
      //         title: 'Sr. Backend Developer',
      //         short_description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.',
      //         icons: [
      //             {   icon: 'geo-alt-fill',
      //                 location: 'Location',
      //                 descript: 'Phnom Penh',
      //             },
      //             {
      //                 icon: 'briefcase-fill',
      //                 location: 'How Work',
      //                 descript: 'On Sit',
      //             },
      //         ],
      //         post_date: 'Posted: June 6, 2022',
      //         btn: 'Apply',
      //     },
      //     {
      //         company_name: 'by Xanex Comp',
      //         title: 'Jr. UI Designer',
      //         short_description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.',
      //         icons: [
      //             {
      //                 icon: 'geo-alt-fill',
      //                 location: 'Location',
      //                 descript: 'NYC',
      //             },
      //             {
      //                 icon: 'briefcase-fill',
      //                 location: 'How Work',
      //                 descript: 'Remote',
      //             },
      //         ],
      //         post_date: 'Posted: June 6, 2022',
      //         btn: 'Apply',
      //     },
      // ],
    };
  },
  methods: {
    ...mapActions(["fetchCareer"]),
  },
  computed: {
    ...mapGetters(["career"]),
  },
  mounted() {
    this.fetchCareer({
      filter: {
        locale: this.$router.currentRoute.params.locale
          ? this.$router.currentRoute.params.locale
          : "en",
      },
    });
  },
  watch: {
    $route(to, from) {
      if (from.params.locale && to.params.locale !== from.params.locale) {
        this.fetchCareer({
          filter: {
            locale: this.$router.currentRoute.params.locale
              ? this.$router.currentRoute.params.locale
              : "en",
          },
        });
      }
    },
  },
};
</script>


<style lang="scss">
@import "@assets/scss/pages/career/career.scss";
</style>