<template>
    <div class="career-detail-page">
        <b-container>
            <b-row>
                <b-col md="8">
                    <b-card no-body class="border-0">
                        <div>
                            <b-card-title><h4>{{ careerDetail && careerDetail.career && careerDetail.career.title }}</h4></b-card-title>
                            <b-card-text><h6 class="sub-title">{{ careerDetail && careerDetail.career && careerDetail.career.post_date }}</h6></b-card-text>
                            <b-card-text class="mb-4"><p>{{ careerDetail && careerDetail.career && careerDetail.career.short_description }}</p></b-card-text>
                            <div class="form-icon">
                                <div class="divider" />
                                <div class="main">
                                    <div class="content">
                                        <p class="mb-2">  
                                            <b-icon icon="geo-alt-fill"></b-icon> &nbsp; location
                                        </p>
                                        <h6 class="location">{{ careerDetail && careerDetail.career && careerDetail.career.location }}</h6>
                                    </div>
                                    <div class="content">
                                        <p class="mb-2">  
                                            <b-icon icon="briefcase-fill"></b-icon> &nbsp; How Work
                                        </p>
                                        <h6 class="location">{{ careerDetail && careerDetail.career && careerDetail.career.how_work }}</h6>
                                    </div>
                                </div>
                                <div class="divider" />
                            </div> 
                            <router-link :to="{ name:'Consultant'}" class="btn-main"> Apply now
                                <b-icon-arrow-right-short></b-icon-arrow-right-short>
                            </router-link>
                            <!-- <a href="#" >
                                <b-icon-arrow-right-short></b-icon-arrow-right-short>
                            </a> -->
                        </div>
                    </b-card>

                    <b-card no-body class="border-0">
                        <div class="mb-4">
                            <b-card-text v-html="careerDetail && careerDetail.career && careerDetail.career.description"></b-card-text>
                        </div>
                    </b-card>
                </b-col>

                <b-col md="4">
                    <b-card-group columns>
                        <b-card class="border-0" >
                            <b-card-body class="cd-detail">
                                <b-card-title>{{ careerDetail && careerDetail.career && careerDetail.career.position }}</b-card-title>
                                <b-card-text>{{ careerDetail && careerDetail.career && careerDetail.career.address}}</b-card-text>
                            </b-card-body>
                        </b-card>
                    </b-card-group>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import Button from '@/core/components/button/Button';
    import { mapActions, mapGetters } from 'vuex';
    export default{
        name: 'CareerDetail',
        components: {
            Button,
        },
        data() {
            return {
                // career_detail_infos:[
                //     {
                //         id:1,
                //         title:'Sr. Backend Developer',
                //         sub_title:'by Nebula Corp',
                //         description:'Nullam leo lectus, vulputate ac nisi at, venenatis sollicitudin justo. Duis sed leo tristique, posuere leo at, fringilla eros. Donec consequat commodo massa a dapibus. In facilisis mollis maximus. Donec quis ornare nunc. Fusce in ipsum non lectus volutpat tristique. Ut scelerisque commodo tellus',
                //         icons: [
                //             {   icon: 'geo-alt-fill',
                //                 location: 'Location',
                //                 descript: 'Phnom Penh',
                //             },
                //             {   
                //                 icon: 'briefcase-fill',
                //                 location: 'How Work',
                //                 descript: 'On Sit',
                //             },
                //         ],
                //         btn: 'Apply Now'
                //     }
                // ],
                // career_detail_content: [
                //     {
                //         id:1,
                //         title:'Sr. Backend Developer',
                //         description:'Nullam leo lectus, vulputate ac nisi at, venenatis sollicitudin justo. Duis sed leo tristique, posuere leo at, fringilla eros. Donec consequat commodo massa a dapibus. In facilisis mollis maximus. Donec quis ornare nunc. Fusce in ipsum non lectus volutpat tristique. Ut scelerisque commodo tellus'
                //     }
                // ],
                // career_detail_form: [
                //     {
                //         id:1,
                //         title: 'Career Level',
                //         sub_title: 'Senior / Expert',
                //     },
                //     {
                //         id:2,
                //         title: 'Specialization',
                //         sub_title: 'Mobile Backend PHP',
                //     },
                //     {
                //         id:3,
                //         title: 'Educational',
                //         sub_title: 'Bachelor or Course Certified',
                //     }
                // ]
            }
        },
        methods: {
        ...mapActions(['fetchCareerDetail']),
        
        },
        computed: {
        ...mapGetters(['careerDetail']),
    
        },
        mounted() {
            this.fetchCareerDetail({
                filter: {
                locale: this.$router.currentRoute.params.locale
                    ? this.$router.currentRoute.params.locale
                    : 'en',
                },
                uuid: this.$router.currentRoute.query._post ? this.$router.currentRoute.query._post: null,
            });
        },
        watch: {
            $route(to, from) {
                if (from.params.locale && to.params.locale !== from.params.locale) {
                this.fetchCareerDetail({
                    filter: {
                        locale: this.$router.currentRoute.params.locale
                        ? this.$router.currentRoute.params.locale
                        : "en",
                    },
                    uuid: this.$router.currentRoute.query
                    ? this.$router.currentRoute.query._post
                    : null,
                });
            }
            },
        },
    }
</script>


<style lang="scss">
    @import "@assets/scss/pages/career-detail/career-detail.scss";
</style>