<template>
    <div class="about app">
        <Breadcrumb 
            :title="about && about.breadcrumb && about.breadcrumb.title ? about.breadcrumb.title : breadcrumbTitle " 
            :backgroundImg="about && about.breadcrumb && assetUrl+about.breadcrumb.image ? assetUrl+about.breadcrumb.image : breadcrubBackground "
        ></Breadcrumb>

        <section class="s-about sy">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 center">
                        <div class="content-center">
                            <div class="box-content" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="200">
                                <h5 class="head-title">{{ about && about.content && about.content.head_title }}</h5>
                                <div class="title">
                                    <h1>{{about && about.content && about.content.title}}</h1>
                                </div>
                                <div class="descript">
                                    <p>{{about && about.content && about.content.descript}}</p>
                                </div>
                                <div class="key-point" v-if="about && about.keyword && about.keyword.length > 0">
                                    <div class="item" v-for="(list , index) in about.keyword" :key="index">
                                        <div class="image">
                                            <img :src="list.icon_image ? assetUrl + list.icon_image : require('@/assets/images/service/value.png') " class="img-fluid" :alt="list.title" />
                                        </div>{{ list.icon }}
                                        <div class="center content">
                                            <div class="content-center">
                                                <div class="box-content">
                                                    <div class="title">
                                                        <h5>{{list.title}}</h5>
                                                    </div>
                                                    <div class="des" v-html="list.description"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <Button
                                    :link="{ name: 'About' }"
                                    class="bg-color"
                                    title="Learn More"
                                ></Button> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 center-image">
                        <div class="content-center">
                            <div class="image circle-shap" >
                                <img :src="about && about.content && assetUrl + about.content.image_about" 
                                :alt="about && about.content && about.content.title" class="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <AdChooseUs></AdChooseUs>

        <Testimonail></Testimonail>
    </div>
</template>


<script>
    import Button from '@/core/components/button/Button';
    import Breadcrumb from '@/core/components/breadcrumb/Breadcrumb.vue';
    import AdChooseUs from "@/components/ad-choose-us/AdChooseUs";
    import Testimonail from '@/components/testimonail/Testimonail.vue';
    import { mapActions, mapGetters } from 'vuex';

    export default{

        components: {
            Button,
            Breadcrumb,
            AdChooseUs,
           
            Testimonail
        },
        data() {
            return {
                assetUrl: process.env.VUE_APP_ASSET_URL + '/',
                breadcrumbTitle: 'Abouts',
                breadcrubBackground: require('@/assets/images/breadcrumb/Artboard4.jpg'),
            }
        },
        methods: {
      ...mapActions(['fetchAbout']),
    
        },
        computed: {
        ...mapGetters(['about']),
    
        },
        mounted() {
        this.fetchAbout({
            filter: {
            locale: this.$router.currentRoute.params.locale
                ? this.$router.currentRoute.params.locale
                : 'en',
            },
        });
        },
        watch: {
        $route(to, from) {
        if (from.params.locale && to.params.locale !== from.params.locale) {
            this.fetchAbout({
            filter: {
                locale: this.$router.currentRoute.params.locale
                ? this.$router.currentRoute.params.locale
                : "en",
            },
            });
        }
        },
        },         

        // seo
        metaInfo () {
            let locale = localStorage.getItem('_locale')
            locale = locale ? JSON.parse(locale) : null
            return {
                title: (this.about && this.about.breadcrumb && this.about.breadcrumb.title
                ? this.about.breadcrumb.title
                : 'About'),
                titleTemplate:
                        '%s | ' +
                        (this.$root.general.settings.app_name
                        ? this.$root.general.settings.app_name
                        : 'Aditi Wesbite'),
                htmlAttrs: {
                lang: locale && locale.title ? locale.title : 'en'
                },
                meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: (this.about && this.about.breadcrumb && this.about.breadcrumb.description)
                    ? this.about.breadcrumb.description
                    : (this.$root.general.settings.description
                        ? this.$root.general.settings.description
                        : '')
                },
                {
                    vmid: 'keyword',
                    name: 'keyword',
                    content: (this.about && this.about.breadcrumb && this.about.breadcrumb.keyword)
                    ? this.about.breadcrumb.keyword
                    : ''
                }
                ]
            }
        },
        
    };
</script>


<style lang="scss">
    @import '@assets/scss/pages/about/about';
</style>