<template>
  <div class="faq">
    <breadcrumb
      :title="faq && faq.breadcrumb && faq.breadcrumb.title ? faq.breadcrumb.title:breadcrumbTitle"
      :backgroundImg="faq && faq.breadcrumb && assetUrl+faq.breadcrumb.image ? assetUrl+faq.breadcrumb.image:breadcrubBackground"
    >
    </breadcrumb>
    <div class="content-wrap">
      <AdAccordion></AdAccordion>
    </div>
    <div class="slide-section">
    </div>
  </div>
</template>

<script>
import breadcrumb from "@core/components/breadcrumb/Breadcrumb";
import AdAccordion from "@/components/ad-accordion/AdAccordion";
  import { mapActions, mapGetters } from 'vuex';
export default {
  name: "Faq",

  components:{
    breadcrumb,
    AdAccordion,
  },

  data() {
    return{
      assetUrl: process.env.VUE_APP_ASSET_URL + '/',
      // title:'FAQ',
      show:true,
      main_content_title:'Don’t Hesited To Contact With Us, We’re Very Friendly',
      form_title:'LET’S TALK WITH US',
      form_sub_title:'Free Consultations',
      // bg:require("@assets/images/faq/faq_bg.png"),
      custom_classes:'header_content_have_img',
      breadcrumbTitle: 'FAQ',
      breadcrubBackground: require('@assets/images/faq/faq_bg.png'),
      form: {
        email: '',
      },
    }
  },

  methods: {
    ...mapActions(['fetchFaq']),
  },

  computed: {
    ...mapGetters(['faq']),
  },

  mounted() {
    this.fetchFaq({
      filter: {
        locale: this.$router.currentRoute.params.locale
          ? this.$router.currentRoute.params.locale
          : 'en',
      },
    });
  },

  watch: {
    $route(to, from) {
      if (from.params.locale && to.params.locale !== from.params.locale) {
        this.fetchFaq({
          filter: {
            locale: this.$router.currentRoute.params.locale
              ? this.$router.currentRoute.params.locale
              : 'en',
          },
        });
      }
    },
  },

  // seo
  metaInfo () {
    let locale = localStorage.getItem('_locale')
    locale = locale ? JSON.parse(locale) : null
    return {
      title: (this.faq && this.faq.breadcrumb && this.faq.breadcrumb.title
      ? this.faq.breadcrumb.title
      : 'faq'),
      titleTemplate:
              '%s | ' +
              (this.$root.general.settings.app_name
              ? this.$root.general.settings.app_name
              : 'Aditi Wesbite'),
      htmlAttrs: {
      lang: locale && locale.title ? locale.title : 'en'
      },
      meta: [
      {
          vmid: 'description',
          name: 'description',
          content: (this.faq && this.faq.breadcrumb && this.faq.breadcrumb.description)
          ? this.faq.breadcrumb.description
          : (this.$root.general.settings.description
              ? this.$root.general.settings.description
              : '')
      },
      {
          vmid: 'keyword',
          name: 'keyword',
          content: (this.faq && this.faq.breadcrumb && this.faq.breadcrumb.keyword)
          ? this.faq.breadcrumb.keyword
          : ''
      }
      ]
    }
  },

}
</script>

<style lang="scss">
@import "@assets/scss/pages/faq/faq.scss";
@import '@core/assets/scss/swiper';
</style>