import { ApiService } from "@core/services";
import { api } from "@core/config/api";

export class ContactService extends ApiService {
  endpoint = api.endpoint;

  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }
  async fetchContactUs(params) {
    return super.fetch(this.endpoint.get_contact, {
      params: params,
      retries: 2,
      transformResponse: [
        function (data) {
          if (!data) {
            return {
              contents: [],
              slides: [],
              success: false,
            };
          }
          return JSON.parse(data).data;
        },
      ],
    });
  }
  async fetchContact(data) {
    return this.post(this.endpoint.sent_contact, data, {
      headers: {
        "Subject": "multipart/form-data",
      },
      transformRequest: [
        function (data, headers) {
          headers["Subject"] = "multipart/form-data";
          return data;
        },
      ],
    });
  }
}