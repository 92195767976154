<!-- <template>
  <div class="condition app">
    <Breadcrumb
      :title="condition && condition.breadcrumb && condition.breadcrumb.title ? condition.breadcrumb.title :breadcrumbTitle "
      :backgroundImg="condition && condition.breadcrumb && assetUrl+condition.breadcrumb.image ? assetUrl+condition.breadcrumb.image : breadcrubBackground"
    ></Breadcrumb>
    <section class="s-condition">
      <div class="container">
        <div class="box-content">
          <div class="title">
            <h2>{{ condition && condition.termContent && condition.termContent.title }}</h2>
          </div>
          <div class="descript" v-html="condition && condition.termContent && condition.termContent.descript">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import Breadcrumb from '@/core/components/breadcrumb/Breadcrumb.vue';
  export default {

    components: {
      Breadcrumb,
    },

    data() {
      return {
        breadcrumbTitle: 'Condition',
        breadcrubBackground: require('@/assets/images/breadcrumb/Artboard4.jpg'),
        assetUrl: process.env.VUE_APP_ASSET_URL + '/',
      };
    },

    methods: {
      ...mapActions(['fetchCondition']),
    },

    computed: {
      ...mapGetters(['condition']),
    },

    mounted() {
      this.fetchCondition({
        filter: {
          locale: this.$router.currentRoute.params.locale
            ? this.$router.currentRoute.params.locale
            : 'en',
        },
      });
    },

    // seo
    metaInfo () {
      let locale = localStorage.getItem('_locale')
      locale = locale ? JSON.parse(locale) : null
      return {
        title: (this.condition && this.condition.breadcrumb && this.condition.breadcrumb.title
        ? this.condition.breadcrumb.title
        : 'condition'),
        titleTemplate:
                '%s | ' +
                (this.$root.general.settings.app_name
                ? this.$root.general.settings.app_name
                : 'Aditi Wesbite'),
        htmlAttrs: {
        lang: locale && locale.title ? locale.title : 'en'
        },
        meta: [
        {
            vmid: 'description',
            name: 'description',
            content: (this.condition && this.condition.breadcrumb && this.condition.breadcrumb.description)
            ? this.condition.breadcrumb.description
            : (this.$root.general.settings.description
                ? this.$root.general.settings.description
                : '')
        },
        {
            vmid: 'keyword',
            name: 'keyword',
            content: (this.condition && this.condition.breadcrumb && this.condition.breadcrumb.keyword)
            ? this.condition.breadcrumb.keyword
            : ''
        }
        ]
      }
    },

  };
</script>

<style lang="scss">
  @import '@assets/scss/pages/condition/condition';
</style> -->


<template>
  <div>
    <b-button v-for="(collapse, index) in collapses" :key="index" @click="toggleCollapse(index)">
      Toggle Collapse {{ index + 1 }}
    </b-button>

    <b-collapse v-for="(collapse, index) in collapses" :key="index" v-model="collapse.show">
      <div class="p-2 bg-light">Content for Collapse {{ index + 1 }}</div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  data() {
    return {
      collapses: [
        { show: false },
        { show: false },
        // Add more collapse objects as needed
      ],
    };
  },
  methods: {
    toggleCollapse(index) {
      // Toggle the collapse based on the provided index
      this.$set(this.collapses, index, { show: !this.collapses[index].show });
    },
  },
};
</script>

<style>
/* Add your custom styles here */
</style>
