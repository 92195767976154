var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-post"},_vm._l((_vm.categoryDetail && _vm.categoryDetail.category && _vm.categoryDetail.category.blogs),function(info,id){return _c('b-card',{key:id,staticClass:"mb-2"},[_c('b-link',[_c('b-card-img',{staticClass:"img-fluid card-img-top",attrs:{"src":_vm.assetUrl+info.image,"alt":info.title}})],1),_c('b-card-body',[_c('b-card-title',[_c('h1',[_c('b-link',[_vm._v(_vm._s(info.title))])],1)]),_c('div',{staticClass:"post-meta"},[_c('div',{staticClass:"post-date"},[_c('b-link',[_c('b-icon',{staticClass:"calendar-icon",attrs:{"icon":"calendar2-week"}}),_c('span',[_vm._v(_vm._s(info.date))])],1)],1)]),_c('b-card-text',[_c('b-link',[_c('p',{staticClass:"four-line",domProps:{"innerHTML":_vm._s(info.first_description)}})])],1)],1),_c('b-card-footer',[_c('div',{staticClass:"profile"},[_c('div',{staticClass:"blog-author-meta"},[_c('div',{staticClass:"author-thumbnail"},[_c('b-img',{staticClass:"img-fluid",attrs:{"src":_vm.assetUrl+info.profile,"alt":info.writer}})],1),_c('div',{staticClass:"blog-author-name"},[_c('h5',[_vm._v("by techex-admin")])])]),_c('div',{staticClass:"post-read-more"},[_c('b-link',{attrs:{"to":{
            name: 'BlogDetail',
            query: {
              _post: info.uuid,
              text: info.slug,
            },
          }}},[_vm._v("Read More"),_c('b-icon',{staticClass:"arrow-right",attrs:{"icon":"arrow-right"}})],1)],1)])])],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }