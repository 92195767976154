<template>
<div class="team-page">
  <div class="header">
    <breadcrumb
        v-bind:title="team && team.breadcrumb && team.breadcrumb.title ? team.breadcrumb.title : bg_title"
        v-bind:backgroundImg="team && team.breadcrumb && assetUrl+team.breadcrumb.image ? assetUrl+team.breadcrumb.image : bg_Breadcrumb"
    >
    </breadcrumb>
  </div>
  <div class="main-content sy">
    <div class="container">
      <div class="title-wrap text-center">
        <h5 class="head-title">{{team && team.content && team.content.headTitle}}</h5>
        <div class="title"><h1>{{team && team.content && team.content.title}}</h1></div>
        
      </div>
      <div class="list-member">
        <AdTeamCard v-bind:team_infos="team.team"></AdTeamCard>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import breadcrumb from "@core/components/breadcrumb/Breadcrumb";
import AdTeamCard from "@/components/ad-team-card/AdTeamCard";
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "Team",
  
  components:{
    breadcrumb,
    AdTeamCard
  },

  data (){
    return{
      bg_title:"Team",
      title:'EXCLUSIVE MEMBERS',
      main_title:'Meet Our Experience Team',
      bg_Breadcrumb:require("@assets/images/blog-details/blog_detail_02.jpg"),
      custom_classes:'header_content_have_img',
      assetUrl: process.env.VUE_APP_ASSET_URL + '/',
    }
  },

  methods: {
      ...mapActions(['fetchTeam']),
  },

  computed: {
    ...mapGetters(['team']),
  },

  mounted() {
    this.fetchTeam({
      filter: {
        locale: this.$router.currentRoute.params.locale
          ? this.$router.currentRoute.params.locale
          : 'en',
      },
    });
    
  },

  watch: {
    $route(to, from) {
      if (from.params.locale && to.params.locale !== from.params.locale) {
        this.fetchTeam({
          filter: {
            locale: this.$router.currentRoute.params.locale
              ? this.$router.currentRoute.params.locale
              : "en",
          },
        });
      }
    },
  },

  // seo
  metaInfo () {
    let locale = localStorage.getItem('_locale')
    locale = locale ? JSON.parse(locale) : null
    return {
      title: (this.team && this.team.breadcrumb && this.team.breadcrumb.title
      ? this.team.breadcrumb.title
      : 'Team'),
      titleTemplate:
              '%s | ' +
              (this.$root.general.settings.app_name
              ? this.$root.general.settings.app_name
              : 'Aditi Wesbite'),
      htmlAttrs: {
      lang: locale && locale.title ? locale.title : 'en'
      },
      meta: [
      {
          vmid: 'description',
          name: 'description',
          content: (this.team && this.team.breadcrumb && this.team.breadcrumb.description)
          ? this.team.breadcrumb.description
          : (this.$root.general.settings.description
              ? this.$root.general.settings.description
              : '')
      },
      {
          vmid: 'keyword',
          name: 'keyword',
          content: (this.team && this.team.breadcrumb && this.team.breadcrumb.keyword)
          ? this.team.breadcrumb.keyword
          : ''
      }
      ]
    }
  },

}
</script>

<style lang="scss">
@import "@assets/scss/pages/team/team.scss";
</style>