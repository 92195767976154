import api from "@/api";
const state = {
    counter: {},
    testimonial:{},
    chooseUs:{},
    services:{},
    serviceDetail:{},
}
const getters = {
    counter: (state) => state.counter,
    testimonial: (state) => state.testimonial,
    chooseUs: (state) => state.chooseUs,
    services: (state) => state.services,
    serviceDetail: (state) => state.serviceDetail,
};
const actions = {
    async fetchCounter({commit} , params){
        await api.component.fetchCounter(params).then((response) => {
            commit("setCounter" , response.data);
        })
    },
    async fetchTestimonial({commit} , params){
        await api.component.fetchTestimonial(params).then((response) => {
            commit("setTestimonial" , response.data);
        })
    },
    async fetchChooseUs({commit} , params){
        await api.component.fetchChooseUs(params).then((response) => {
            commit("setChooseUs" , response.data);
        })
    },
    async fetchService({commit} , params){
        await api.component.fetchService(params).then((response) => {
            commit("setService" , response.data);
        })
    },
    async fetchServiceDetail({commit} , params){
        await api.component.fetchServiceDetail(params).then((response) => {
            commit("setServiceDetail" , response.data);
        })
    }
}
const mutations = {
    setCounter: (state, counter) => (state.counter = counter),
    setTestimonial: (state, testimonial) => (state.testimonial = testimonial),
    setChooseUs: (state, chooseUs) => (state.chooseUs = chooseUs),
    setService: (state, service) => (state.service = service),
    setServiceDetail: (state, serviceDetail) => (state.serviceDetail = serviceDetail),

};
export default{
    state,
    getters,
    actions,
    mutations,
};