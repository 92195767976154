import { ApiService } from '@core/services'
import { api } from '@core/config/api'

export class TranslationService extends ApiService {
    endpoint=api.endpoint;
    // eslint-disable-next-line no-useless-constructor
    constructor () {
      super()
    }

    async fetchTranslates (params) {
      return super.fetch(this.endpoint.translates, {
        params: params,
        transformResponse: [function (data) {
          if (!data) {
            return {
              items: []
            }
          }
          return JSON.parse(data)
        }]
      });
    }
}
